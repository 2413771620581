import BaseResponse from "../../model/base/BaseResponse";
import ResultString from "../../model/base/ResultString";
import {request} from "../util/ConnectUtil";
import {HttpMethod} from "../util/HttpMethod";
import PostQuestionRequest from "../../model/question/PostQuestionRequest";
import ContentInfo from "../../model/question/ContentInfo";
import BaseListResponse from "../../model/base/BaseListResponse";
import PostAnswerRequest from "../../model/question/PostAnswerRequest";

async function deleteContents(id: string): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.DELETE, `/contents/${id}`)
}

async function postQuestion(req: PostQuestionRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, '/questions', undefined, req)
}

async function putQuestion(id: string, req: PostQuestionRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.PUT, `/questions/${id}`, undefined, req)
}

async function getContents(id: string): Promise<BaseResponse<ContentInfo>> {
    return await request(HttpMethod.GET, `/contents/${id}`)
}

async function getQuestionList(query: {
    topicId?: string,
    isAnswered?: boolean,
    search?: string,
    withCount?: boolean,
    indexOfPage?: number,
    countInPage?: number
}): Promise<BaseListResponse<ContentInfo>> {
    return await request(HttpMethod.GET, `/questions`, query)
}

async function getMyQuestionList(query: {
    isAnswered?: boolean,
    withCount?: boolean,
    indexOfPage?: number,
    countInPage?: number
}): Promise<BaseListResponse<ContentInfo>> {
    return await request(HttpMethod.GET, `/me/questions`, query)
}

async function getTrendingQuestionList(): Promise<BaseListResponse<ContentInfo>> {
    return await request(HttpMethod.GET, `/questions/trending`)
}

async function getAnswers(questionId: string, query: {
    withCount?: boolean,
    indexOfPage?: number,
    countInPage?: number
}): Promise<BaseListResponse<ContentInfo>> {
    return await request(HttpMethod.GET, `/questions/${questionId}/answers`, query)
}

async function postAnswer(questionId: string, req: PostAnswerRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, `/questions/${questionId}/answers`, undefined, req)
}

async function putAnswer(questionId: string, answerId: string, req: PostAnswerRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.PUT, `/questions/${questionId}/answers/${answerId}`, undefined, req)
}

export default {
    postQuestion, getContents, getQuestionList, deleteContents, putQuestion, getMyQuestionList, getTrendingQuestionList, getAnswers, postAnswer, putAnswer
}
