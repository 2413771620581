export default class EmailSignUpRequest {
    constructor(
        public readonly email: string,
        public readonly password: string,
        public readonly firstName: string,
        public readonly lastName: string,
        public readonly phoneNumber: string,
        public readonly code: string,
        public readonly deviceUuid: string
    ) {
    }
}
