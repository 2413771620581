// basic functions

function setItem(key: string, value: string) {
    localStorage.setItem(key, value)
}

function getItem(key: string, defaultValue?: string): string {
    return localStorage.getItem(key) ?? defaultValue ?? ''
}

function removeItem(key: string) {
    localStorage.removeItem(key)
}

function consumeItem(key: string): string {
    const result = localStorage.getItem(key)
    removeItem(key)
    return result ?? ''
}

function clear() {
    localStorage.clear()
}

export default {
    setItem,
    getItem,
    removeItem,
    consumeItem,
    clear,
}
