import styled from "styled-components";
import {TextProps} from "./StyledDiv";

export default styled.span<TextProps>`
  color: ${props => {
    if (props.color && props.color in props.theme.colors) {
      // @ts-ignore
      return props.theme.colors[props.color]
    } else return props.color
  }};
  ${props => props.font ? props.theme.fonts[props.font] : undefined};
`
