import React from 'react';
import {Breadcrumb} from "antd";
import {RightOutlined} from "@ant-design/icons";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import {useTranslation} from "react-i18next";
import Topic from "../../model/counsel/Topic";
import {getTransMapData} from "../../model/question/TranslateInfo";
import I18nUtil from "../../translations/I18nUtil";
import {useNavigate} from "react-router";

function SearchNavigatorView(props: { topic?: Topic }) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <div>
            <Breadcrumb style={{margin: '25px 0'}} separator={<RightOutlined/>}>
                <Breadcrumb.Item onClick={event => navigate('/')}>
                    <StyledSpan color={'Color828282'}
                                font={'RoboRegular16'}>
                        {t('Home')}
                    </StyledSpan>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <StyledSpan color={'Color828282'}
                                font={'RoboBold16'}>
                        {props.topic?.id ? getTransMapData(props.topic.title, I18nUtil.getLanguage()) : t('Search')}
                    </StyledSpan>
                </Breadcrumb.Item>
            </Breadcrumb>
        </div>
    );
}

export default SearchNavigatorView;
