import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import ContentInfo from "../../model/question/ContentInfo";
import ContentsApi from "../../api/manager/ContentsApi";
import {Button, Col, message, Row, Spin} from "antd";
import TranslateInfo, {getTransMapData} from "../../model/question/TranslateInfo";
import styled from "styled-components";
import User from "../../model/account/User";
import UserApi from "../../api/manager/UserApi";
import {LocateType} from "../../model/question/LocateType";
import AdminContentsApi from "../api/manager/AdminContentsApi";
import AdminContentsRequest from "../model/AdminPutQuestionRequest";
import QuestionDetailView from "../../components/question/QuestionDetailView";
import HtmlContentEditView from "../../components/content/HtmlContentEditView";

const ContentContainer = styled.div`
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 24px 50px 24px 50px;
`

function AdminAnswerDetailPage(props: {}) {

    const navigate = useNavigate();
    const {id} = useParams();
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false);
    const [editAnswer, setEditAnswer] = useState<ContentInfo>();
    const [createdBy, setCreatedBy] = useState<User>();

    //KR
    const [koHtml, setKoHtml] = useState('');

    //EN
    const [enHtml, setEnHtml] = useState('');

    useEffect(() => {
        if (!id) return
        setLoading(true)
        ContentsApi.getContents(id)
            .then(res => {
                if (res.data) {
                    setEditAnswer(res.data)
                    setKoHtml(getTransMapData(res.data.descHtml, LocateType.ko) ?? '')
                    setEnHtml(getTransMapData(res.data.descHtml, LocateType.en) ?? '')
                }
                return UserApi.getUser(res.data!.createdBy!._id)
            })
            .then(res => {
                setLoading(false)
                setCreatedBy(res.data)
            })
            .catchApi(e => {
                setLoading(false)
                message.error(e.msg)
                console.error(e)
            })
    }, [id]);

    const handleEditAnswer = useCallback(() => {
        if (!editAnswer) return

        if (!koHtml || !enHtml) {
            message.error(t('error.inputAll'))
            return
        }
        setLoading(true)

        AdminContentsApi.putContents(editAnswer._id, new AdminContentsRequest(
            undefined,
            undefined,
            new TranslateInfo<string>(editAnswer.question?.descHtml?.oriLocate ?? LocateType.ko, {ko: koHtml, en: enHtml}),
        )).then(res => {
            setLoading(false)
            navigate(-1)
        }).catchApi(e => {
            setLoading(false)
            message.error(e.msg)
        })

    }, [navigate, koHtml, enHtml, editAnswer])

    return (
        <Spin spinning={loading}>
            <div style={{padding: 24}}>
                <div style={{fontSize: 48, marginBottom: 24, background: 'gray'}}>관리자 답변 수정</div>
                <QuestionDetailView contentInfo={editAnswer?.question}/>
                <div style={{height: 64}}/>
                {/*KO*/}
                <div style={{fontSize: 48, marginBottom: 12}}>한국어</div>
                <ContentContainer>
                    <div style={{marginTop: 24, marginBottom: 200}}>
                        <HtmlContentEditView initialHtml={koHtml} onChange={html => setKoHtml(html)}/>
                    </div>
                </ContentContainer>
                {/*EN*/}
                <div style={{fontSize: 48, marginBottom: 12, marginTop: 36}}>English</div>
                <ContentContainer>
                    <div style={{marginTop: 24, marginBottom: 200}}>
                        <HtmlContentEditView initialHtml={enHtml} onChange={html => setEnHtml(html)}/>
                    </div>
                </ContentContainer>
                <div style={{marginTop: 12}}>
                    <Row>
                        <Col flex={"auto"}/>
                        <Col>
                            <Button type={"primary"}
                                    style={{width: 176}}
                                    size={"large"}
                                    onClick={handleEditAnswer}>
                                {'답변 수정하기'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Spin>
    );
}

export default AdminAnswerDetailPage;
