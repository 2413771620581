const emailReg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
/**6~15자 특수문자, 숫자 포함*/
const passwordReg = new RegExp(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*()\-_+=\[\]{}\\|'":;/?.>,<₩`~]).{8,20}$/)
/**HH:MM ex. 14:30*/
const HH_COLON_MM_REGEX = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
/**한글*/
const ANY_HANGEUL = /[가-힣ㄱ-ㅎㅏ-ㅣ]/gi

function validateEmail(email?: string): boolean {
    return email ? emailReg.test(email) : false
}

function validatePassword(pwd?: string): boolean {
    return pwd ? passwordReg.test(pwd) : false
}

function containHangeul(str?: string): boolean {
    if (!str) return false
    return ANY_HANGEUL.test(str)
}

export default {
    HH_COLON_MM_REGEX,
    validateEmail, validatePassword, containHangeul
}
