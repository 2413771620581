import {Cookies} from "react-cookie";
import {CookieGetOptions, CookieSetOptions} from "universal-cookie/cjs/types";

const cookies = new Cookies()
const DEFAULT_EXPIRED_MILLIS: number = 259200000; // 1000 * 60 * 60 * 24 * 3, 3days

function setCookie(key: string, value: any, expiredMillis: number = DEFAULT_EXPIRED_MILLIS) {
    let expiredDate = new Date(Date.now() + expiredMillis);
    cookies.set(key, value, {expires: expiredDate})
}

function extendExpiredDate(key: string) {
    let value = cookies.get(key);
    // reset cookie
    setCookie(key, value);
}

function getCookie(key: string, options?: CookieGetOptions): any {
    return cookies.get(key, options)
}

function removeCookie(key: string, options?: CookieSetOptions) {
    cookies.remove(key, options)
}

enum CookieKey {
    AccessToken = '_aw_tk',
}

export default {
    CookieKey,
    setCookie, extendExpiredDate, getCookie, removeCookie
}
