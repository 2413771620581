import React, {useCallback, useEffect, useState} from 'react';
import {AutoComplete, Col, Input, Row} from "antd";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import RecentSearchTextUtil from "../../../util/RecentSearchTextUtil";
import {CloseOutlined, FieldTimeOutlined} from "@ant-design/icons";
import Lodash from "../../../util/Lodash";


const SearchBoxContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #F9F9F9;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2px 5px 2px 5px;

  .ant-select {
    width: 100%;
  }

  .ant-input {
    border: 0px;
    box-shadow: unset;
  }

  .ant-btn {
    border: 0px;
    box-shadow: unset;
  }
`

function SearchInputBoxView(props: {
    onSearch?: (searchText?: string) => void
}) {

    const {t} = useTranslation()

    const [searchParams, setSearchParams] = useSearchParams();

    const [searchText, setSearchText] = useState<string>('')
    const [recentSearchTextList, setRecentSearchTextList] = useState<string[]>([])
    const [recentSearchKeywordOptions, setRecentSearchKeywordOptions] = useState<{}[]>([])

    useEffect(() => {

        // load recent search text
        setRecentSearchTextList(prevState => {
            return RecentSearchTextUtil.loadRecentSearchKeyword()
        })

    }, [])

    useEffect(() => {

        // options
        setRecentSearchKeywordOptions(prevState => {
            return [{
                label: renderTitle(t('Recent searches')),
                options: recentSearchTextList.map(value => {
                    return renderItem(value)
                }),
            }]
        })

    }, [recentSearchTextList])

    useEffect(() => {
        setSearchText(searchParams.get('keyword') ?? '')
    }, [searchParams]);

    const renderTitle = (title: string) => (
        <StyledSpan color={'Color393939'}
                    font={'RoboRegular16'}>
            {title}
        </StyledSpan>
    );

    const renderItem = (searchText: string) => ({
        value: searchText,
        label: (
            <Row align={"middle"} gutter={[6, 6]}>
                <Col>
                    <FieldTimeOutlined/>
                </Col>
                <Col flex={"auto"}>
                    <StyledSpan color={'Black'}
                                font={'RoboRegular14'}>
                        {searchText}
                    </StyledSpan>
                </Col>
                <Col>
                    <CloseOutlined onClick={(e) => {
                        handleRemoveRecentSearchKeyword(searchText)
                        e.stopPropagation()
                    }}/>
                </Col>
            </Row>
        ),
    });

    const handleRemoveRecentSearchKeyword = useCallback((searchText?: string) => {

        if (!Lodash.isUndefined(searchText) && searchText.length > 0) {
            // #1. remove
            RecentSearchTextUtil.removeRecentSearchKeyword(searchText)

            // #2. reload
            setRecentSearchTextList(prevState => {
                return RecentSearchTextUtil.loadRecentSearchKeyword()
            })
        }

    }, [])

    const handleOnSearch = useCallback((searchText?: string) => {

        // #1. notify
        if (props.onSearch) {
            props.onSearch(searchText)
        }

        // #2. reset text
        setSearchText('')

        // #3. save
        RecentSearchTextUtil.saveRecentSearchKeyword(searchText)

    }, [props.onSearch]);

    const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchText(e.target.value)
    }, []);

    return (
        <>
            <SearchBoxContainer>
                <AutoComplete
                    popupClassName="certain-category-search-dropdown"
                    options={recentSearchKeywordOptions}>
                    <Input.Search value={searchText}
                                  size="large"
                                  placeholder={`${t('Search Your questions')}`}
                                  onChange={handleOnChange}
                                  onSearch={handleOnSearch}/>
                </AutoComplete>
            </SearchBoxContainer>
        </>
    );
}

export default SearchInputBoxView;
