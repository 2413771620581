import apiConf from "./ApiConf";
import axios, {ResponseType} from "axios";
import qs from "qs";
import {HttpMethod} from "./HttpMethod";
import CookieManager from "../../util/CookieManager";
import {v4 as uuidv4} from "uuid";
import FileUtil from "../../util/FileUtil";
import I18nUtil from "../../translations/I18nUtil";

const DEFAULT_TIMEOUT = 300000 //5분

async function request<RESPONSE>(method: HttpMethod, path: string, params?: object, body?: any, endpointUrl?: string, skipAuth?: boolean): Promise<RESPONSE> {
    const headers: { [key: string]: any } = {
        'Accept-Language': I18nUtil.getLanguage(),
        'Accept': 'application/json',
    }
    if (!skipAuth) headers['X-AUTH-TOKEN'] = CookieManager.getCookie(CookieManager.CookieKey.AccessToken) ?? ""

    return await axios.request({
        method: method,
        url: endpointUrl ? endpointUrl + path : apiConf.endpointUrl + path,
        headers: headers,
        timeout: DEFAULT_TIMEOUT,
        data: body,
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }).then((res) => {
        return res.data
    })
}

async function requestMultipart<RESPONSE>(method: HttpMethod, path: string, params?: object, body?: FormData, endpointUrl?: string, skipAuth?: boolean): Promise<RESPONSE> {
    const headers: { [key: string]: any } = {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': I18nUtil.getLanguage(),
        'Accept': 'application/json',
    }
    if (!skipAuth) headers['X-AUTH-TOKEN'] = CookieManager.getCookie(CookieManager.CookieKey.AccessToken) ?? ""


    return await axios.request({
        method: method,
        url: endpointUrl ? endpointUrl + path : apiConf.endpointUrl + path,
        headers: headers,
        timeout: DEFAULT_TIMEOUT,
        data: body,
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }).then((res) => {
        return res.data
    })
}

const upload = async (additionalData?: object, fileBlobData?: Blob | string | null, fileName?: string) => {

    console.log('upload additional data: ' + JSON.stringify(additionalData));

    const formData = new FormData();
    let uploadFileName = fileName
    if (!uploadFileName) {
        if (!fileBlobData) uploadFileName = uuidv4()
        else if (typeof fileBlobData === 'string') uploadFileName = uuidv4()
        else uploadFileName = await FileUtil.getRandomFileName(fileBlobData)
    }
    formData.append('file', fileBlobData ?? '', uploadFileName);
    formData.append('data', JSON.stringify(additionalData));

    const headers = {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': I18nUtil.getLanguage(),
        'Accept': 'application/json',
        'X-AUTH-TOKEN': CookieManager.getCookie(CookieManager.CookieKey.AccessToken) ?? '',
    }

    return await axios.request({
        method: 'POST',
        url: apiConf.endpointUrl + '/files',
        headers: headers,
        timeout: DEFAULT_TIMEOUT,
        data: formData,
        params: null
    })
}

//public url 을 넘기면, 퍼센티 이미지 url 로 컨버팅
async function uploadUrl<RESPONSE>(body: { url: string, isPublic: boolean, noChangeFilename: boolean, isCreateThumbnail: boolean, filePath: string, isResize: boolean, isImage: boolean }): Promise<RESPONSE> {
    const headers: { [key: string]: any } = {
        'Accept-Language': I18nUtil.getLanguage(),
        'Accept': 'application/json',
    }
    headers['X-AUTH-TOKEN'] = CookieManager.getCookie(CookieManager.CookieKey.AccessToken) ?? ""

    return await axios.request({
        method: 'POST',
        url: apiConf.endpointUrl + '/files/url',
        headers: headers,
        timeout: DEFAULT_TIMEOUT,
        data: body,
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }).then((res) => {
        return res.data
    })
}

const download = async (urlParam?: string, responseType: ResponseType | undefined = undefined) => {

    const headers = {
        'Accept-Language': I18nUtil.getLanguage(),
        'Accept': 'application/octet-stream',
        'X-AUTH-TOKEN': CookieManager.getCookie(CookieManager.CookieKey.AccessToken) ?? '',
    }

    return await axios.request({
        method: 'GET',
        url: apiConf.endpointUrl + '/download',
        headers: headers,
        timeout: DEFAULT_TIMEOUT,
        data: null,
        responseType: responseType,
        params: {
            url: urlParam ?? ''
        }
    })
}

async function downloadPublicFile(publicUrl: string, responseType: ResponseType | undefined = undefined) {
    return await axios.request({
        method: 'GET',
        url: publicUrl,
        responseType: responseType,
    })
}

const downloadFromS3 = async (path: string, responseType: ResponseType | undefined = undefined) => {

    const headers = {
        'Accept-Language': I18nUtil.getLanguage(),
        'Accept': 'application/octet-stream',
        'Cache-Control': 'no-cache',
        'X-AUTH-TOKEN': CookieManager.getCookie(CookieManager.CookieKey.AccessToken) ?? '',
    }
    const refinedPath = path.startsWith('/') ? path : `/${path}`

    return await axios.request({
        method: 'GET',
        url: apiConf.S3Url + refinedPath,
        headers: headers,
        timeout: DEFAULT_TIMEOUT,
        data: null,
        responseType: responseType,
    })
}

const downloadExcel = async (path: string, body?: any) => {

    const headers = {
        'Content-Type': 'application/json',
        'Accept-Language': I18nUtil.getLanguage(),
        'Accept': 'application/octet-stream',
        'X-AUTH-TOKEN': CookieManager.getCookie(CookieManager.CookieKey.AccessToken) ?? '',
    }

    return await axios.request({
        method: 'POST',
        url: apiConf.endpointUrl + path,
        headers: headers,
        timeout: DEFAULT_TIMEOUT,
        data: body,
        responseType: "blob",
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    })
}

export {
    request, upload, uploadUrl, download, downloadFromS3, requestMultipart, downloadExcel, downloadPublicFile
}
