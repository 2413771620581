const DEV_MODE = false;

const DevServerConfiguration = {
    baseUrl: 'http://ec2-52-78-58-30.ap-northeast-2.compute.amazonaws.com:8080',
    fileUrl: 'https://file.allwhiz.com',
    S3Url: '',
}

const ProdServerConfiguration = {
    baseUrl: 'https://api.allwhiz.com',
    fileUrl: 'https://file.allwhiz.com',
    S3Url: '',
}

const conf = {
    'isDevMode': DEV_MODE,
    'endpointUrl': DEV_MODE ? DevServerConfiguration.baseUrl : ProdServerConfiguration.baseUrl,
    'imageEndpointUrl': '',
    'fileUrlPrefix': DEV_MODE ? DevServerConfiguration.fileUrl : ProdServerConfiguration.fileUrl,
    'S3Url': DEV_MODE ? DevServerConfiguration.S3Url : ProdServerConfiguration.S3Url,

    //etc
    'facebookAppId': '429785075022280',
    'googleIdentityClientId': '55364486260-26ta3asi02cudsd9l9hrhe8r9v7o4u8h.apps.googleusercontent.com',
    'allWhizMobileLink': 'https://allwhiz.page.link/AllWhizApp',
    'allWhizGooglePlayLink': 'https://play.google.com/store/apps/details?id=com.allwhiz',
}

export default conf
