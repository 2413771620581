import {v4 as uuidv4} from 'uuid'

async function fileToBlob(file?: File): Promise<Blob | undefined> {
    return fileReader(file)
        .then(res => dataURLtoBlob(res))
}

function fileReader(file?: File): Promise<string> {
    return new Promise((resolve, reject) => {
        if (!file) {
            return resolve('');
        }
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result?.toString() ?? '');
        fileReader.onerror = () => reject('file read error');
        fileReader.readAsDataURL(file);
    });
}

function dataURLtoBlob(dataUrl?: string): Blob | undefined {
    if (!dataUrl) return
    let arr = dataUrl.split(','), mime = arr[0]?.match(/:(.*?);/)?.[1],
        bStr = atob(arr[1]), n = bStr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bStr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

function openFileSelectDialog(multiple: boolean, accept: ('image/*' | 'video/*' | 'audio/*' | '.png' | '.jpg' | '.pdf' | '.hwp' | string)[], callback: (fileList: File[]) => void) {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = multiple;
    input.accept = accept.join(',');

    input.onchange = (event: Event) => {
        const fileList = (event.target as HTMLInputElement)?.files
        if (!fileList || fileList.length === 0) {
            callback([])
            return
        }

        const result: File[] = []
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i)
            if (file) result.push(file)
        }
        callback(result)
    }

    input.click();
}

async function getRandomFileName(blob: Blob, defaultExtensionName?: string): Promise<string> {
    let extension = await getFileExtension(blob)
    if (!extension && defaultExtensionName) extension = defaultExtensionName
    return uuidv4() + '.' + extension
}

async function getFileExtension(blob: Blob): Promise<string> {
    const mimeType = await getMimeType(blob)
    let extension = mimeToExtension(mimeType)
    if (extension.length === 0) extension = mimeToExtension(blob.type)
    return extension
}

function getFileNameFromUrl(url?: string, withExtension: boolean = false): string {
    if (!url) return ''
    const lastSlashIndex = url.lastIndexOf('/')
    if (lastSlashIndex < 0) return ''
    const fileNameWithExtension = url.substring(lastSlashIndex + 1)
    if (withExtension) return fileNameWithExtension
    else return fileNameWithExtension.replace(getFileExtensionFromUrl(url), '').replace('.', '')
}

function getFileExtensionFromUrl(url: string): string {
    const regexp = /\.([0-9a-z]+)(?:[?#]|$)/i;
    const extension = url.match(regexp);
    return extension?.[1] ?? '';
}

async function getMimeType(blob: Blob): Promise<string> {
    return new Promise((resolve) => {
        try {
            const fileReader = new FileReader()

            fileReader.onloadend = async function (evt) {
                const uint = new Uint8Array(await blob.arrayBuffer())
                let bytes: string[] = []

                uint.forEach((byte) => {
                    bytes.push(byte.toString(16))
                })
                const hex = bytes.join('').toUpperCase()

                const mimeType = magicNumberToMime(hex)
                resolve(mimeType)
            }

            fileReader.readAsArrayBuffer(blob.slice(0, 4))
        } catch (e: any) {
            console.error(e)
            resolve('')
        }
    })
}

function magicNumberToMime(signature: string) {
    if (signature.startsWith('89504E47')) return 'image/png'
    if (signature.startsWith('47494638')) return 'image/gif'
    if (signature.startsWith('25504446')) return 'application/pdf'
    if (signature.startsWith('FFD8FFDB')) return 'image/jpeg'
    if (signature.startsWith('FFD8FFE0')) return 'image/jpeg'
    if (signature.startsWith('FFD8FFE1')) return 'image/jpeg'
    if (signature.startsWith('504B0304')) return 'application/zip'
    if (signature.startsWith('424D')) return 'image/bmp'

    // if (signature.startsWith('0000001866747970')) return 'video/mp4'//mp4
    // if (signature.startsWith('464C56')) return 'video/x-flv'//flv
    // if (signature.startsWith('52494646')) return 'video/x-msvideo'//avi
    // if (signature.startsWith('66747970')) return 'video/3gpp'//3gp
    // if (signature.startsWith('66747970')) return 'application/x-mpegURL'//m3u8
    // if (signature.startsWith('66747970')) return 'video/MP2T'//ts
    // if (signature.startsWith('66747970')) return 'video/quicktime'//mov
    // if (signature.startsWith('66747970')) return 'video/x-ms-wmv'//wmv
    return ''
}

function mimeToExtension(mimeType: string): string {

    switch (mimeType) {
        case 'audio/aac':
            return 'aac'
        case 'application/octet-stream':
            return 'bin'
        case  'application/x-bzip':
            return 'bz'
        case 'application/x-bzip2':
            return 'bz2'
        case 'text/css':
            return 'css'
        case 'text/csv':
            return 'csv'
        case 'application/msword':
            return 'doc'
        case 'image/gif':
            return 'gif'
        case 'text/html':
            return 'html'
        case 'image/x-icon':
            return 'ico'
        case 'application/java-archive':
            return 'jar'
        case 'image/jpeg':
            return 'jpeg'
        case 'image/png':
            return 'png'
        case 'image/bmp':
            return 'bmp'
        case 'image/svg+xml':
            return 'svg'
        case 'image/webp':
            return 'webp'
        case 'application/js':
            return 'js'
        case 'application/json':
            return 'json'
        case 'audio/midi':
            return 'midi'
        case 'video/mpeg':
            return 'mpeg'
        case 'application/vnd.oasis.opendocument.presentation':
            return 'odp'
        case 'application/vnd.oasis.opendocument.spreadsheet':
            return 'ods'
        case 'application/vnd.oasis.opendocument.text':
            return 'odt'
        case 'audio/ogg':
            return 'oga'
        case 'video/ogg':
            return 'ogv'
        case 'application/ogg':
            return 'ogx'
        case 'application/pdf':
            return 'pdf'
        case 'application/vnd.ms-powerpoint':
            return 'ppt'
        case 'application/x-rar-compressed':
            return 'rar'
        case 'application/rtf':
            return 'rtf'
        case 'application/x-sh':
            return 'sh'
        case 'application/x-shockwave-flash':
            return 'swf'
        case 'application/x-tar':
            return 'tar'
        case 'image/tiff':
            return 'tiff'
        case 'application/x-font-ttf':
            return 'ttf'
        case 'application/vnd.visio':
            return 'vsd'
        case 'audio/x-wav':
            return 'wav'
        case 'audio/webm':
            return 'weba'
        case 'video/webm':
            return 'webm'
        case 'application/x-font-woff':
            return 'woff'
        case 'application/xhtml+xml':
            return 'xhtml'
        case 'application/vnd.ms-excel':
            return 'xls'
        case 'application/xml':
            return 'xml'
        case 'application/vnd.mozilla.xul+xml':
            return 'xul'
        case 'application/zip':
            return 'zip'
        case 'video/3gpp':
            return '3gp'
        case 'audio/3gpp':
            return '3gp'
        case 'video/3gpp2':
            return '3g2'
        case 'audio/3gpp2':
            return '3g2'
        case 'application/x-7z-compressed':
            return '7z'
        default:
            return ''
    }
}

export default {
    fileToBlob,
    fileReader,
    dataURLtoBlob,
    openFileSelectDialog,
    getMimeType,
    getRandomFileName,
    getFileNameFromUrl,
    getFileExtension,
    getFileExtensionFromUrl
}
