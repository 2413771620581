import React, {useEffect, useState} from 'react';
import ContentInfo from "../../../model/question/ContentInfo";
import styled from "styled-components";
import {Col, Image, Row} from "antd";
import imgQuestionTag from "../../../assets/images/img_question_tag.svg";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import ConvertUtil from "../../../util/ConvertUtil";
import {Link} from "react-router-dom";
import {getTransMapData} from "../../../model/question/TranslateInfo";
import I18nUtil from "../../../translations/I18nUtil";
import EventBus from "../../../eventbus/EventBus";
import {LanguageChangeEvent} from "../../../eventbus/Event";

const QuestionItemContainer = styled.div`
  width: 100%;
  height: 130px;
  padding: 16px 12px 16px 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(138, 138, 138, 0.6);
  border-radius: 8px;
`

function TrendingQAListItemView(props: {
    contentInfo?: ContentInfo
}) {

    const [questionTitle, setQuestionTitle] = useState<string>('')
    const [bottomText, setBottomText] = useState<string>('')
    const [forceRender, setForceRender] = useState<{}>();

    useEffect(() => {
        const subs = EventBus.subscribe(LanguageChangeEvent, ev => {
            setForceRender({})
        })
        return () => subs.unsubscribe()
    }, [])

    useEffect(() => {

        // title
        setQuestionTitle(prevState => {
            return getTransMapData(props.contentInfo?.title, I18nUtil.getLanguage()) ?? ''
        })

        // TODO - bottom text, topic id -> title
        setBottomText(prevState => {
            return `${props.contentInfo?.topicId} • Total Answers: ${ConvertUtil.thousandSeparatorWithNumber(props.contentInfo?.cntAnswer)}`
        })

    }, [props.contentInfo, forceRender])

    return (
        <Link to={`/question/${props.contentInfo?._id}`}>
            <QuestionItemContainer>
                <Row wrap={false} gutter={[5, 5]}>
                    <Col flex={"none"}>
                        <Image width={16}
                               preview={false}
                               src={imgQuestionTag}/>
                    </Col>
                    <Col flex={"auto"}>
                        <StyledSpan color={'Black'}
                                    font={'RoboRegular14'}>
                            {questionTitle}
                        </StyledSpan>
                    </Col>
                </Row>
                <StyledSpan color={'Color828282'}
                            font={'RoboRegular12'}
                            style={{
                                position: "absolute",
                                bottom: 16
                            }}>
                    {bottomText}
                </StyledSpan>
            </QuestionItemContainer>
        </Link>
    );
}

export default TrendingQAListItemView;
