import TranslateInfo from "../../model/question/TranslateInfo";

export default class AdminContentsRequest {
    constructor(
        public readonly topicId: string | undefined,
        public readonly title: TranslateInfo<string> | undefined,
        public readonly descHtml: TranslateInfo<string>,
    ) {
    }
}
