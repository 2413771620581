import {filter, Subject, Subscription} from "rxjs";
import {IEvent} from "./Event";

const eventSubject = new Subject<any>()

function subscribe<T extends IEvent>(constructor: { new(): T }, observer: (ev: T) => void): Subscription {
    return eventSubject
        .pipe(filter(value => value instanceof constructor))
        .subscribe(observer)
}


function sendEvent(event: IEvent) {
    eventSubject.next(event)
}

const bus = {
    subscribe, sendEvent
}


export default bus
