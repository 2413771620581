import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Col, Image, Row} from "antd";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import ApiConf from "../../../api/util/ApiConf";

const TopicItemContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(138, 138, 138, 0.6);
  height: 100%;
`

function TopicListItemView(props: {
    topicId?: string,
    title?: string,
    imageUrl?: string
}) {

    useEffect(() => {

    }, [])

    return (
        <TopicItemContainer>
            <Link style={{
                textDecoration: 'none'
            }}
                  to={`/search?topicId=${props.topicId}`}>
                <div style={{
                    aspectRatio: 1.5,
                    paddingTop: 18,
                    paddingBottom: 18,
                    paddingLeft: 26,
                    paddingRight: 26
                }}>
                    <Row>
                        <StyledSpan color={'Black'}
                                    font={'RoboMedium18'}>
                            {props.title}
                        </StyledSpan>
                    </Row>
                    <Row justify={"end"}>
                        <Col span={16}>
                            <Image preview={false}
                                   style={{objectFit: "contain"}}
                                   src={`${ApiConf.fileUrlPrefix}/${props.imageUrl}`}/>
                        </Col>
                    </Row>
                </div>
            </Link>
        </TopicItemContainer>
    );
}

export default TopicListItemView;
