import StyledDiv from 'components/base/styledcomponents/StyledDiv';
import React from 'react';
import {useTranslation} from "react-i18next";
import BasePageLayout from "../base/layout/BasePageLayout";

function ContactUsPage(props: {}) {
    const {t} = useTranslation()

    return (
        <BasePageLayout style={{height: '100vh'}}>
            <div style={{height: 62}}/>
            <StyledDiv font={'RoboBold36'}>{t('Contact us')}</StyledDiv>
            <div style={{height: 24}}/>
            <StyledDiv font={'RoboRegular20'}>{t('Please submit any service-related questions to us through our app.')}</StyledDiv>
            <div style={{height: 16}}/>
            <StyledDiv font={'RoboRegular20'}>
                {t('If you have any other questions, please reach out to')}
            </StyledDiv>
            <div style={{height: 16}}/>
            <StyledDiv font={'RoboRegular20'}>
                <a href={'mailto:support@allwhiz.com'}> {t('support@allwhiz.com')}</a>
            </StyledDiv>
        </BasePageLayout>
    );
}

export default ContactUsPage;
