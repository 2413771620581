import React, {useEffect, useState} from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import {useSearchParams} from "react-router-dom";
import SearchPageHeaderView from "../search/SearchPageHeaderView";
import SearchNavigatorView from "../search/SearchNavigatorView";
import CreateWhizProfileBannerView from "../main/CreateWhizProfileBannerView";
import SearchQuestionTabView from "../search/SearchQuestionTabView";
import Topic from "../../model/counsel/Topic";
import PreferenceApi from "../../api/manager/PreferenceApi";
import {message} from "antd";

function SearchPage(props: {}) {

    const [searchParams, setSearchParams] = useSearchParams();

    const [topic, setTopic] = useState<Topic>()

    useEffect(() => {

        const topicId = searchParams.get('topicId')
        PreferenceApi.getTopic(topicId).then(res => {
            setTopic(res)
        }).catchApi(e => message.error(e.msg))

    }, [searchParams])

    return (
        <BasePageLayout>
            <SearchNavigatorView topic={topic}/>
            <SearchPageHeaderView topic={topic}/>
            <div style={{marginTop: 64}}>
                <SearchQuestionTabView/>
            </div>
            <div style={{marginTop: 64, marginBottom: 89}}>
                <CreateWhizProfileBannerView/>
            </div>
        </BasePageLayout>
    );
}

export default SearchPage;
