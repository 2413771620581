import React from 'react';
import {Col, Row} from "antd";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import User from "../../../model/account/User";
import whizProfileDefaultImg from "../../../assets/images/img_whiz_default.svg";
import ApiConf from "../../../api/util/ApiConf";

function CreateQuestionUserInfoView(props: { user?: User }) {

    return (
        <>
            <Row align={"middle"} gutter={[12, 12]}>
                <Col>
                    <img style={{
                        width: 24,
                        height: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#F6F6F6',
                        borderRadius: 12,
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        objectFit: "contain"
                    }}
                         src={props.user?.profileImageUuid ? `${ApiConf.fileUrlPrefix}/${props.user?.profileImageUuid}` : whizProfileDefaultImg}/>
                </Col>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboMedium24'}>
                        {`${props.user?.lastName ?? ''} ${props.user?.firstName ?? ''} `}
                    </StyledSpan>
                </Col>
            </Row>
        </>
    );
}

export default CreateQuestionUserInfoView;
