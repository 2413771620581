import React, {useState} from 'react';
import logoWithText from "../../assets/images/logo_with_text.svg";
import StyledDiv from "../base/styledcomponents/StyledDiv";
import {Col, Input, Row, Select} from "antd";
import WhiteButton from "../base/WhiteButton";
import StyledSignBackground from "../base/styledcomponents/StyledSignBackground";
import {useTranslation} from "react-i18next";
import PhoneNumberUtil from "../../util/PhoneNumberUtil";

function SignUpPhoneNumberView(props: {
    phoneCountryCallingCode: string, setPhoneCountryCallingCode: React.Dispatch<string>,
    phoneNumber: string, setPhoneNumber: React.Dispatch<string>,
    onClickNext: () => void
}) {
    const {t} = useTranslation()
    const [countryCallingCodes, setCountryCallingCodes] = useState(PhoneNumberUtil.getAllCountryCallingCodes().map(v => ({value: `+${v}`})));

    return (
        <StyledSignBackground style={{display: "flex", alignItems: "center", flexDirection: "column", paddingTop: 80, height: '100%'}}>
            <img src={logoWithText}/>
            <div>
                <div style={{height: 85}}/>
                <StyledDiv font={'RoboBold32'} color={'White'}>{t('Let’s set up your account')}</StyledDiv>
                <div style={{height: 8}}/>
                <StyledDiv font={'RoboRegular16'} color={'White'}>{t('Enter your phone number and we will text you an authentication code.')}</StyledDiv>
                <div style={{height: 31}}/>
                <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('Phone number')}</StyledDiv>

                <Row align={"middle"}>
                    <Col flex={"auto"}>
                        <Select style={{width: 80, marginRight: 8}} defaultValue={props.phoneCountryCallingCode} size={"large"}
                                onChange={value => props.setPhoneCountryCallingCode(value)} options={countryCallingCodes}/>
                    </Col>
                    <Col flex={"auto"}>
                        <Input placeholder={t('(415) 000 - 0000')!} bordered={true}
                               value={props.phoneNumber}
                               onChange={event => props.setPhoneNumber(event.target.value)}
                               style={{width: 500, height: 55}}/>
                    </Col>
                </Row>


                <div style={{height: 144}}/>
                <WhiteButton font={'RoboBold20'} text={t('Continue')} style={{width: 586, height: 74}} onClick={props.onClickNext}/>
            </div>

            <div style={{height: 80}}/>
        </StyledSignBackground>
    );
}

export default SignUpPhoneNumberView;
