import ApiError from "./ApiError";
import axios from "axios";

export interface ErrorAction {
    type?: string
    uri?: string;
}

export enum ErrorActionLinkType {
    NONE,
    PROFILES_COUPANG,
    PROFILES_SMARTSTORE,
    PROFILES_ESM,
    PROFILES_ELEVENSTREET,
    PROFILES_ELEVENSTREET_GLOBAL,
    PROFILES_LOTTEON,
    PROFILES_AUCTION,
    PROFILES_INTERPARK,
}

/**ErrorAction 을 사용하기 쉽게 converting 한 결과*/
export class ParsedErrorAction {
    constructor(
        public readonly linkType: ErrorActionLinkType,
        public readonly originalAction: ErrorAction,
    ) {
    }

    isProfileEmptyError(): boolean {
        return this.linkType === ErrorActionLinkType.PROFILES_COUPANG ||
            this.linkType === ErrorActionLinkType.PROFILES_SMARTSTORE ||
            this.linkType === ErrorActionLinkType.PROFILES_ESM ||
            this.linkType === ErrorActionLinkType.PROFILES_ELEVENSTREET ||
            this.linkType === ErrorActionLinkType.PROFILES_ELEVENSTREET_GLOBAL ||
            this.linkType === ErrorActionLinkType.PROFILES_LOTTEON ||
            this.linkType === ErrorActionLinkType.PROFILES_AUCTION ||
            this.linkType === ErrorActionLinkType.PROFILES_INTERPARK
    }
}

export function parseApiError(error: any): ApiError {
    if (axios.isAxiosError(error)) {
        const code = error?.response?.data?.code ?? -1
        const msg = error?.response?.data?.msg ?? ''
        const action = error?.response?.data?.action
        return new ApiError(code, parseErrorAction(action as ErrorAction), msg, error)
    } else {
        return new ApiError(-1, parseErrorAction(), error?.message ? error.message : '알수없는 오류가 발생했습니다.')
    }
}

export function parseErrorAction(action?: ErrorAction): ParsedErrorAction {
    if (!action) return new ParsedErrorAction(ErrorActionLinkType.NONE, {})
    if (action.uri === 'profiles/cp') return new ParsedErrorAction(ErrorActionLinkType.PROFILES_COUPANG, action)
    else if (action.uri === 'profiles/ss') return new ParsedErrorAction(ErrorActionLinkType.PROFILES_SMARTSTORE, action)
    else if (action.uri === 'profiles/esm') return new ParsedErrorAction(ErrorActionLinkType.PROFILES_ESM, action)
    else if (action.uri === 'profiles/est') return new ParsedErrorAction(ErrorActionLinkType.PROFILES_ELEVENSTREET, action)
    else if (action.uri === 'profiles/est_global') return new ParsedErrorAction(ErrorActionLinkType.PROFILES_ELEVENSTREET_GLOBAL, action)
    else if (action.uri === 'profiles/lotteon') return new ParsedErrorAction(ErrorActionLinkType.PROFILES_LOTTEON, action)
    else if (action.uri === 'profiles/at') return new ParsedErrorAction(ErrorActionLinkType.PROFILES_AUCTION, action)
    else if (action.uri === 'profiles/ip') return new ParsedErrorAction(ErrorActionLinkType.PROFILES_INTERPARK, action)
    else return new ParsedErrorAction(ErrorActionLinkType.NONE, {})
}
