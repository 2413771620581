import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Input, message, Modal, Row} from "antd";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import editIconImage from "../../../assets/images/icon_edit_icon.svg"
import styled from "styled-components";
import User from "../../../model/account/User";
import {useTranslation} from "react-i18next";
import ApiConf from "../../../api/util/ApiConf";
import StyledDiv from 'components/base/styledcomponents/StyledDiv';
import UserApi from "../../../api/manager/UserApi";
import UserPutRequest from "../../../model/sign/UserPutRequest";
import {useAppDispatch} from "../../../reduxes/ReduxStore";
import {userAction} from "../../../reduxes/slices/userSlice";


const ButtonContainer = styled.div`
  .ant-btn {
    padding-left: 0;
  }
`

function UserProfileInfoView(props: {
    userInfo?: User
}) {

    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    const [userName, setUserName] = useState<string>('')
    const [userEmail, setUserEmail] = useState<string>('')

    const [showNameChangeModal, setShowNameChangeModal] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loadingNickNameChange, setLoadingNickNameChange] = useState(false);

    useEffect(() => {

        // #1. user name
        setUserName(prevState => {
            return `${props.userInfo?.lastName ?? ''} ${props.userInfo?.firstName ?? ''} `
        })

        setFirstName(props.userInfo?.firstName ?? '')
        setLastName(props.userInfo?.lastName ?? '')

        // #2. email
        setUserEmail(prevState => {
            return props.userInfo?.email ?? ''
        })

    }, [props.userInfo])

    const handleEditName = useCallback(() => {
        setShowNameChangeModal(true)
    }, [])


    const handleClickMyProfile = useCallback(() => {
        window.open(ApiConf.allWhizMobileLink, '_blank')
    }, [])

    const handleChangeNameOk = useCallback(() => {
        if (!firstName || !lastName) {
            message.error(t('error.inputAll'))
            return
        }

        setLoadingNickNameChange(true)
        UserApi.putMe(new UserPutRequest(props.userInfo?.profileImageUuid, firstName, lastName))
            .then(res => {
                setLoadingNickNameChange(false)
                if (res.data) dispatch(userAction.setUser(res.data))
                setShowNameChangeModal(false)
            })
            .catchApi(e => {
                setLoadingNickNameChange(false)
                message.error(e.msg)
            })
    }, [props.userInfo, firstName, lastName]);

    return (
        <>
            <Row align={"middle"} gutter={[10, 10]}>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboBold20'}>
                        {t('Name')}
                    </StyledSpan>
                </Col>
                <Col>
                    <img draggable={false} style={{
                        width: 18,
                        height: 18,
                        cursor: "pointer",
                    }} src={editIconImage} onClick={handleEditName}/>
                </Col>
            </Row>
            <Row style={{marginTop: 5}}>
                <Col>
                    <StyledSpan color={'Default'}
                                font={'RoboRegular24'}>
                        {userName}
                    </StyledSpan>
                </Col>
            </Row>
            <Row style={{marginTop: 15}} align={"middle"} gutter={[10, 10]}>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboBold20'}>
                        {t('E-mail address')}
                    </StyledSpan>
                </Col>
            </Row>
            <Row style={{marginTop: 5}}>
                <Col>
                    <StyledSpan color={'Default'}
                                font={'RoboRegular24'}>
                        {userEmail}
                    </StyledSpan>
                </Col>
            </Row>
            <Row style={{marginTop: 27}}>
                <Col>
                    <ButtonContainer>
                        <Button size={"large"} type="link"
                                onClick={handleClickMyProfile}>
                            {`${t('Check my Whiz Profile')} →`}
                        </Button>
                    </ButtonContainer>
                </Col>
            </Row>
            <Modal open={showNameChangeModal} onOk={handleChangeNameOk} onCancel={e => setShowNameChangeModal(false)} confirmLoading={loadingNickNameChange}>
                <div style={{height: 16}}/>
                <StyledDiv>{t('First Name')}</StyledDiv>
                <Input value={firstName} onChange={event => setFirstName(event.target.value)}/>
                <div style={{height: 16}}/>
                <StyledDiv>{t('Last Name')}</StyledDiv>
                <Input value={lastName} onChange={event => setLastName(event.target.value)}/>
            </Modal>
        </>
    );
}

export default UserProfileInfoView;
