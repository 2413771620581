import React, {useEffect, useState} from 'react';
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import {message, Radio, RadioChangeEvent} from "antd";
import PreferenceApi from "../../../api/manager/PreferenceApi";
import {getTransMapData} from "../../../model/question/TranslateInfo";
import I18nUtil from "../../../translations/I18nUtil";
import {useTranslation} from "react-i18next";

function CreateQuestionCategoryView(props: { defaultCategoryId?: string, onCategoryChange: (topicId: string) => void }) {

    const {t} = useTranslation()

    const [categoryList, setCategoryList] = useState<{ label: string, value: string }[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>();

    useEffect(() => {
        PreferenceApi.getTopics().then(response => {
            const result: { label: string, value: string }[] = []
            const career = response.filter(value => value.id === 'career').shift()
            const edu = response.filter(value => value.id === 'education').shift()
            const living = response.filter(value => value.id === 'living').shift()
            if (career) result.push({
                label: getTransMapData(career.title, I18nUtil.getLanguage()) ?? '',
                value: career.id
            })
            if (edu) result.push({label: getTransMapData(edu.title, I18nUtil.getLanguage()) ?? '', value: edu.id})
            if (living) result.push({
                label: getTransMapData(living.title, I18nUtil.getLanguage()) ?? '',
                value: living.id
            })
            setCategoryList(result)
        }).catchApi(e => {
            message.error(e.msg)
        })

    }, [])

    useEffect(() => {
        if (!props.defaultCategoryId || categoryList.length === 0) return
        setSelectedCategory(props.defaultCategoryId)
    }, [props.defaultCategoryId, categoryList]);

    const onCategorySelectedChange = ({target: {value}}: RadioChangeEvent) => {
        props.onCategoryChange(value)
    };

    return (
        <>
            <StyledSpan color={'Black'}
                        font={'RoboMedium20'}>
                {t('Category')}
            </StyledSpan>
            <div style={{marginTop: 8}}>
                <Radio.Group options={categoryList}
                             value={selectedCategory}
                             onChange={onCategorySelectedChange}/>
            </div>
        </>
    );
}

export default CreateQuestionCategoryView;
