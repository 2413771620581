function copy(text?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        if (!text) {
            reject()
            return
        }

        if (navigator.clipboard) {
            // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
            navigator.clipboard
                .writeText(text)
                .then(() => resolve())
                .catch(() => reject())
        } else {
            if (!document.queryCommandSupported("copy")) {
                reject('복사하기가 지원되지 않는 브라우저입니다.')
                return
            }

            const textarea = document.createElement("textarea");
            textarea.value = text;
            textarea.style.top = '0';
            textarea.style.left = '0';
            textarea.style.position = "fixed";

            document.body.appendChild(textarea);
            // focus() -> 사파리 브라우저 서포팅
            textarea.focus();
            // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
            textarea.select();
            document.execCommand("copy");
            // 흐름 6.
            document.body.removeChild(textarea);
            resolve()
        }
    })
}

/**text 뿐만 아니라 이미지 등도 불러올수 있음*/
function read() {
    return navigator.clipboard.read()
}

/**text 만 불러오기 가능*/
function readText() {
    return navigator.clipboard.readText()
}

export default {
    copy, read, readText
}