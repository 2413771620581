import React, {useEffect, useState} from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import MainSearchQuestionsView from "../main/MainSearchQuestionsView";
import MainTopicListView from "../main/MainTopicListView";
import MainTrendingQAListView from "../main/MainTrendingQAListView";
import CreateWhizProfileBannerView from "../main/CreateWhizProfileBannerView";
import HomeApi from "../../api/manager/HomeApi";
import {message} from "antd";
import Topic from "../../model/counsel/Topic";
import ContentInfo from "../../model/question/ContentInfo";
import I18nUtil from "../../translations/I18nUtil";
import {LocateType} from "../../model/question/LocateType";
import EventBus from "../../eventbus/EventBus";
import {LanguageChangeEvent} from "../../eventbus/Event";
import ContentVideoBannerView from "../main/ContentVideoBannerView";
import PartnerImageBannerView from "../main/PartnerImageBannerView";

function MainPage(props: {}) {

    const [locateType, setLocateType] = useState<LocateType>(I18nUtil.getLanguage())

    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [topicInfoList, setTopicInfoList] = useState<Topic[]>([])
    const [trendingContentList, setTrendingContentList] = useState<ContentInfo[]>([])
    const [contentVideoId, setContentVideoId] = useState<string | undefined>(undefined)

    useEffect(() => {

        HomeApi.getHomeData().then(response => {

            console.log('[HOME] get data result', response)

            setTopicInfoList(response.data?.topicList ?? [])
            setTrendingContentList(response.data?.trendingList ?? [])
            setIsLoaded(true)

        }).catchApi(e => {
            message.error(e.msg)
        })

        const subs = EventBus.subscribe(LanguageChangeEvent, ev => {
            setLocateType(I18nUtil.getLanguage())
        })
        return () => subs.unsubscribe()

    }, [])

    useEffect(() => {

        setContentVideoId(prevState => {
            return locateType === LocateType.ko ? 'Opx2NUBsiBw' : undefined
        })

    }, [locateType])

    return (
        <BasePageLayout showCompanyInfo={true}>
            <div style={{marginTop: 64}}>
                <MainSearchQuestionsView/>
            </div>
            <div style={{marginTop: 80}}>
                <MainTopicListView isLoaded={isLoaded}
                                   topicInfoList={topicInfoList}/>
            </div>
            <div style={{marginTop: 80}}>
                <MainTrendingQAListView isLoaded={isLoaded}
                                        trendingContentList={trendingContentList}/>
            </div>
            {
                contentVideoId ? (
                    <div style={{marginTop: 80}}>
                        <ContentVideoBannerView youtubeVideoId={contentVideoId}/>
                    </div>
                ) : undefined
            }
            <div style={{marginTop: 80}}>
                <PartnerImageBannerView/>
            </div>
            <div style={{marginTop: 80, marginBottom: 64}}>
                <CreateWhizProfileBannerView/>
            </div>
        </BasePageLayout>
    );
}

export default MainPage;
