import BaseResponse from "../../model/base/BaseResponse";
import UserPutRequest from "../../model/sign/UserPutRequest";
import ResultString from "../../model/base/ResultString";
import User from "../../model/account/User";
import {request} from "../util/ConnectUtil";
import {HttpMethod} from "../util/HttpMethod";
import BaseListResponse from "../../model/base/BaseListResponse";

/** 내 유저 가져오기 */
async function getMe(): Promise<BaseResponse<User>> {
    return await request(HttpMethod.GET, '/users/me')
}

/** 내 User 정보 수정하기 */
async function putMe(req: UserPutRequest): Promise<BaseResponse<User>> {
    return await request(HttpMethod.PUT, '/users/me', undefined, req)
}

/** 유저 가져오기 */
async function getUser(id: string, withReservations: Boolean = false): Promise<BaseResponse<User>> {
    return await request(HttpMethod.GET, `/users/${id}`, {withReservations: withReservations})
}

async function getWhizLink(whizId: string): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.GET, `/users/${whizId}/link`)
}

/** 유저 가져오기 */
async function getWhizList(keyword?: string, topic?: string, stars?: number,
                           price_low?: number,
                           price_high?: number,
                           language?: string,
                           queryMap?: Map<String, String>
): Promise<BaseListResponse<User>> {
    return await request(HttpMethod.GET, `/users`, {
        keyword: keyword,
        topic: topic,
        stars: stars,
        price_low: price_low,
        price_high: price_high,
        language: language,
        ...queryMap
    })
}

/** token refresh (만료 1달전에) */
async function refreshAuthToken(): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, `/refresh-token`)
}

async function unRegister(): Promise<BaseResponse<undefined>> {
    return await request(HttpMethod.POST, `/unregister`)
}

export default {
    getMe, putMe, getUser, getWhizLink, getWhizList, refreshAuthToken, unRegister
}
