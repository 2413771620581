import React from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import aboutImg from "../../assets/images/about_us_pc.png"


function AboutPage(props: {}) {
    return (
        <BasePageLayout>
            <div style={{height: 56}}/>
            <img src={aboutImg} style={{width: '100%'}}/>
        </BasePageLayout>
    );
}

export default AboutPage;
