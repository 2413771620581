import React, {useEffect, useState} from 'react';
import {Col, Row, Space} from "antd";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import ContentInfo from "../../../model/question/ContentInfo";
import {useTranslation} from "react-i18next";
import {LikeOutlined} from "@ant-design/icons";
import PreferenceApi from "../../../api/manager/PreferenceApi";
import {getTransMapData} from "../../../model/question/TranslateInfo";
import I18nUtil from "../../../translations/I18nUtil";

function QuestionDetailBottomView(props: {
    contentInfo?: ContentInfo
}) {

    const {t} = useTranslation()

    const [topicText, setTopicText] = useState<string>('')

    useEffect(() => {
        PreferenceApi.getTopic(props.contentInfo?.topicId).then(t => setTopicText(getTransMapData(t?.title, I18nUtil.getLanguage()) ?? ''))
    }, [props.contentInfo])

    const IconText = ({icon, text}: { icon: React.FC; text: string }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    return (
        <>
            <Row gutter={[4, 4]}>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboRegular14'}>
                        {`${t('Topic')}:`}
                    </StyledSpan>
                </Col>
                <Col>
                    <StyledSpan color={'Color1F77DD'}
                                font={'RoboRegular14'}>
                        {topicText}
                    </StyledSpan>
                </Col>
                <Col flex={"auto"}/>
            </Row>
        </>
    );
}

export default QuestionDetailBottomView;
