import LocalStorageUtil from "./LocalStorageUtil";

let deviceUuid = ''
const DEVICE_UUID_KEY = 'a_d_uu_k'

function getDeviceUuid(): string {
    if (!deviceUuid) {
        deviceUuid = LocalStorageUtil.getItem(DEVICE_UUID_KEY, '')
    }
    return deviceUuid
}

export default {
    getDeviceUuid
}
