import {LocateType, TransMap} from "./LocateType";
import Lodash from "../../util/Lodash";

export default class TranslateInfo<T> {
    constructor(
        public readonly oriLocate?: LocateType,
        public readonly transMap?: TransMap<T>
    ) {
    }
}

export function getTransMapData<T>(translateInfo?: TranslateInfo<T>, locateType?: LocateType): T | undefined {

    // check empty
    if (!translateInfo || !translateInfo.transMap) {
        return undefined
    }

    if (Lodash.isUndefined(locateType)) {
        // #1. check oriLocate
        if (Lodash.isUndefined(translateInfo.oriLocate)) {
            return undefined
        }
        // #2. oriLocate value
        return translateInfo.transMap[translateInfo.oriLocate]
    }

    // locate type value
    return translateInfo.transMap[locateType]
}
