import {SnsProvider} from "./SnsProvider";

export default class SnsSignUpRequest {
    constructor(
        public readonly deviceUuid: string,
        public readonly email: string,
        public readonly firstName: string,
        public readonly lastName: string,
        public readonly phoneNumber: string,
        public readonly code: string,
        public readonly provider: SnsProvider,
        public readonly token: string
    ) {
    }
}
