import _ from "lodash";

/**
 * number
 */
//return defaultValue 없으면 0
function toInt(value: number | string | String | Number, defaultValue?: number): number {
    if (defaultValue !== undefined) return _.toSafeInteger(value) ?? defaultValue
    else return _.toSafeInteger(value)
}

function gt(value: number | string, comparison: number): boolean {
    return _.gt(value, comparison)
}

function gte(value: number | string, comparison: number): boolean {
    return _.gte(value, comparison)
}

function isNumber(value?: any): boolean {
    return !Number.isNaN(parseFloat(String(value)))
}

/**
 * String
 */
function isBlank(value?: string | any[]): boolean {
    if (!value) return true

    if (_.isString(value)) {
        return value.length === 0 || !value.trim()
    } else {
        return value.length === 0
    }
}

function isNotBlank(str?: string): boolean {
    return !isBlank(str)
}

/**
 * object, array
 */
function isEmpty(obj: object | any[] | undefined): boolean {
    return _.isEmpty(obj)
}

//url 에 scheme 이 없을경우 scheme 추가해줌. default: http
function withUriScheme(uri: string, scheme?: string): string {
    if (uri.includes(':')) return uri
    else {
        return `${scheme ?? 'http'}://${uri.replace(RegExp('^[/]+'), '')}`
    }
}

/**
 * Collection
 */

//collection 안의 모든 item 이 predicate 를 만족하는지 체크 (collection 이 undefined 이거나 비어있으면 true)
function every<T>(collection: T[] | null | undefined, predicate: (item: T) => boolean): boolean {
    return _.every(collection, value => predicate(value))
}

//Remove an item in index (원본 array 변경되며, 삭제한 값만 리턴)
function remove<T>(collection: T[] | undefined, index: number): T[] {
    if (!collection) return []
    return _.pullAt(collection, index)
}

/**
 * ETC
 */

function deepCopy<T>(obj: T): T {
    return _.cloneDeep(obj)
}

function assign(target: object, source1: object, source2?: object, source3?: object, source4?: object): object {
    return _.assign(target, source1, source2, source3, source4)
}

//같은지 비교 (deep comparison)
function isEqual(value: any, other: any): boolean {
    return _.isEqual(value, other)
}

function isUndefined(value: any): value is undefined {
    return _.isUndefined(value)
}

export default {
    toInt, gt, gte, withUriScheme, isNumber,
    isBlank, isNotBlank,
    isEmpty,
    every, remove,
    deepCopy, assign, isEqual,
    isUndefined,
}
