import React, {useEffect} from 'react';
import {Divider} from "antd";
import styled from "styled-components";
import QuestionDetailHeaderView from "./view/QuestionDetailHeaderView";
import HtmlContentDisplayView from "../content/HtmlContentDisplayView";
import QuestionDetailBottomView from "./view/QuestionDetailBottomView";
import ContentInfo from "../../model/question/ContentInfo";

const ContentContainer = styled.div`
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 24px 50px 24px 50px;
`

function QuestionDetailView(props: {
    contentInfo?: ContentInfo
}) {

    useEffect(() => {

    }, [])
    
    return (
        <ContentContainer>
            <QuestionDetailHeaderView contentInfo={props.contentInfo}/>
            <Divider style={{marginTop: 12, marginBottom: 32}}/>
            <HtmlContentDisplayView contentInfo={props.contentInfo}/>
            <div style={{marginTop: 48}}>
                <QuestionDetailBottomView contentInfo={props.contentInfo}/>
            </div>
        </ContentContainer>
    );
}

export default QuestionDetailView;
