import React from 'react';
import {Tabs} from "antd";
import MyQuestionContentListView, {ContentListType} from "../question/list/MyQuestionContentListView";
import {useTranslation} from "react-i18next";

function MyArticleTabView(props: {}) {

    const {t} = useTranslation()

    // TODO
    const onChange = (key: string) => {
        console.log(key);
    };

    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                onChange={onChange}
                items={[
                    {
                        label: `${t('Answered')}`,
                        key: '1',
                        children: <MyQuestionContentListView contentListType={ContentListType.answered}/>,
                    },
                    {
                        label: `${t('Unanswered')}`,
                        key: '2',
                        children: <MyQuestionContentListView contentListType={ContentListType.unanswered}/>,
                    },
                    {
                        label: `${t('Saved')}`,
                        key: '3',
                        children: <MyQuestionContentListView contentListType={ContentListType.saved}/>,
                    },
                ]}
            />
        </div>
    );
}

export default MyArticleTabView;
