import * as crypto from "crypto";
import {Buffer} from "buffer";
import jwtDecode from "jwt-decode";

//encode base64 url friendly
function encodeBase64(data?: string): string {
    if (!data) return ''
    return Buffer.from(data, 'utf8').toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

//decode base64 url friendly
function decodeBase64(str?: string): string {
    if (!str) return ''
    str = str.replace(/-/g, '+').replace(/_/g, '/')
    if (str!.length % 4 != 0) {
        str += ('===').slice(0, 4 - (str.length % 4));
    }
    return Buffer.from(str, 'base64').toString('utf8')
}

function encodeURL(url?: string): string {
    if (!url) return ''
    return encodeURI(url)
}

function decodeURL(url?: string): string {
    if (!url) return ''
    return decodeURI(url)
}

function hash(value?: string): string {
    if (!value) return ''
    return crypto.createHash('sha512').update(value).digest('base64')
}

function parseJwt<R>(jwt?: string): R | undefined {
    try {
        if (!jwt) return undefined
        return jwtDecode(jwt)
    } catch (e: any) {
        console.error(e)
        return undefined
    }
}

export default {
    encodeBase64,
    decodeBase64,
    encodeURL,
    decodeURL,
    hash,
    parseJwt,
}
