import {getCountries, getCountryCallingCode} from "libphonenumber-js";


function getAllCountryCallingCodes(): string[] {
    const result: string[] = []
    for (const c of getCountries()) {
        const callingCode = getCountryCallingCode(c).toString()
        if (!result.includes(callingCode)) result.push(callingCode)
    }
    return result
}

/**
 * @return like +821033600543
 */
function getNationalPhoneNumber(countryCode: string, nationalNumber: string): string {
    let result = countryCode.replace(RegExp("\\D+"), "")
    result = result.concat(nationalNumber.replace(RegExp("\\D+"), "").replace(RegExp("^[0]+"), ""))
    return `+${result}`
}

export default {
    getAllCountryCallingCodes, getNationalPhoneNumber
}
