// function textLabelFromDataSourceId(t: TFunction<"translation", undefined>, sourceId?: DataSourceId): string {
//
//     // TODO - fetch from server, next sprint
//     switch (sourceId) {
//         case DataSourceId.plasys:
//             return t('dataSourceId.plasys')
//         case DataSourceId.gn:
//             return t('dataSourceId.gn')
//         case DataSourceId.gd:
//             return t('dataSourceId.gd')
//         case DataSourceId.bbt:
//             return t('dataSourceId.bbt')
//         default:
//             return ''
//     }
// }

function thousandSeparatorWithString(x?: string) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function thousandSeparatorWithNumber(x?: number) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getFullName(firstName?: string, lastName?: string): string {
    return `${lastName ?? ''} ${firstName ?? ''}`
}


export default {
    thousandSeparatorWithString,
    thousandSeparatorWithNumber,
    getFullName
}
