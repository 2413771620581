import ReportRequest from "../../model/etc/ReportRequest";
import {request} from "../util/ConnectUtil";
import {HttpMethod} from "../util/HttpMethod";
import BaseResponse from "../../model/base/BaseResponse";

async function report(req: ReportRequest): Promise<BaseResponse<undefined>> {
    return await request(HttpMethod.POST, '/contentreports', undefined, req)
}

export default {
    report
}
