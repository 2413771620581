import Topic from "../../model/counsel/Topic";
import ContentInfo from "../../model/question/ContentInfo";
import PreferenceApi from "./PreferenceApi";
import ContentsApi from "./ContentsApi";
import BaseResponse from "../../model/base/BaseResponse";


export type HomeDataInfo = {
    topicList?: Topic[],
    trendingList?: ContentInfo[]
}

async function getHomeData() {

    return new Promise<BaseResponse<HomeDataInfo>>((resolve, reject) => {

        const requestTopics = PreferenceApi.getTopics()
        const requestTrendingQuestions = ContentsApi.getTrendingQuestionList()

        Promise.all([requestTopics, requestTrendingQuestions]).then(response => {

            const dataInfo: HomeDataInfo = {
                topicList: response[0],
                trendingList: response[1].list
            }

            return resolve(new BaseResponse<HomeDataInfo>(true, 'success home data', 200, dataInfo));

        }).catch(reason => {
            console.log(reason)
            return reject('market waybiils error');
        })
    })
}

export default {
    getHomeData
}
