import React from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";

function NotFoundPage(props: {}) {

    return (
        <BasePageLayout>
            <div style={{height: '70vh'}}>
                {'NOT FOUND'}
            </div>
        </BasePageLayout>
    );
}

export default NotFoundPage;
