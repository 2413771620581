import React, {useEffect, useMemo} from 'react';
import {Col, Row, Skeleton} from "antd";
import TrendingQAListItemView from "./view/TrendingQAListItemView";
import {useTranslation} from "react-i18next";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import ContentInfo from "../../model/question/ContentInfo";

function MainTrendingQAListView(props: {
    isLoaded?: boolean,
    trendingContentList?: ContentInfo[]
}) {

    const {t} = useTranslation()

    useEffect(() => {

    }, [])

    const trendingQAListMarkup = useMemo(() => {

        const markups = props.trendingContentList?.map((value, index) => {
            return (
                <Col key={index} span={4}>
                    <TrendingQAListItemView contentInfo={value}/>
                </Col>
            )
        })

        return (markups)

    }, [props.trendingContentList])

    // fix the six count of trending questions
    return (
        <>
            {
                props.isLoaded ? (
                    <>
                        <Row>
                            <Col>
                                <StyledSpan color={'Black'}
                                            font={'RoboBold24'}>
                                    {t('Trending Q&A')}
                                </StyledSpan>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 12}} gutter={[16, 16]}>
                            {trendingQAListMarkup}
                        </Row>
                    </>
                ) : (
                    <Skeleton/>
                )
            }
        </>
    );
}

export default MainTrendingQAListView;
