import i18n, {i18n as I18n} from "i18next";
import {initReactI18next} from "react-i18next";

import {en, ko} from "../translations";
import LocalStorageUtil from "../util/LocalStorageUtil";
import {LocateType} from "../model/question/LocateType";
import EventBus from "../eventbus/EventBus";
import {LanguageChangeEvent} from "../eventbus/Event";

const LANGUAGE_KEY = 'aw_language'

const createI18n = ({language}: { language: LocateType }): I18n => {
    i18n.use(initReactI18next).init({
        resources: {
            ko: {translation: ko},
            en: {translation: en},
        },
        lng: language,
        fallbackLng: "ko",
        interpolation: {escapeValue: false},
    });
    return i18n;
};

const setLanguage = (language: LocateType) => {
    if (typeof window === "undefined") {
        return;
    }
    LocalStorageUtil.setItem(LANGUAGE_KEY, language);
    i18nInstance.changeLanguage(language, error => {
        EventBus.sendEvent(new LanguageChangeEvent())
    })
};

const getLanguage = (): LocateType => {
    if (typeof window === "undefined") {
        return LocateType.ko;
    }

    const localSet = LocalStorageUtil.getItem(LANGUAGE_KEY)
    if (localSet) {
        return localSet === 'ko' ? LocateType.ko : LocateType.en
    }
    return window.navigator.language === 'ko-KR' ? LocateType.ko : LocateType.en
};

const i18nInstance = createI18n({language: getLanguage()})

export default {
    i18nInstance, setLanguage, getLanguage
};
