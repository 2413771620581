import React, {useEffect, useState} from 'react';
import plusButtonIcon from "../../../assets/images/img_plus_button_icon.svg"
import User from "../../../model/account/User";
import ApiConf from "../../../api/util/ApiConf";
import whizProfileDefaultImg from '../../../assets/images/img_whiz_default.svg'
import {message, Spin, Upload, UploadProps} from "antd";
import FileApi from "../../../api/manager/FileApi";
import UserApi from "../../../api/manager/UserApi";
import UserPutRequest from "../../../model/sign/UserPutRequest";
import {useAppDispatch} from "../../../reduxes/ReduxStore";
import {userAction} from "../../../reduxes/slices/userSlice";

function UserProfileImageView(props: {
    userInfo?: User
}) {

    const dispatch = useAppDispatch()
    const [userProfileImageUrl, setUserProfileImageUrl] = useState<string>('');
    const [profileImageLoading, setProfileImageLoading] = useState(false);

    useEffect(() => {

        // #1. profile image
        if (props.userInfo?.profileImageUuid) {
            setUserProfileImageUrl(prevState => {
                return `${ApiConf.fileUrlPrefix}/${props.userInfo?.profileImageUuid}`
            })
        }

    }, [props.userInfo])

    const uploadProps: UploadProps = {
        name: 'file',
        customRequest: options => {
            setProfileImageLoading(true)
            FileApi.uploadImage('/files', options.file)
                .then(res => {
                    return UserApi.putMe(new UserPutRequest(res.data.data))
                })
                .then(res => {
                    if (res.data) dispatch(userAction.setUser(res.data))
                    setProfileImageLoading(false)
                })
                .catchApi(e => {
                    setProfileImageLoading(false)
                    message.error(e.msg)
                })
        },
        multiple: false,
        showUploadList: false
    };

    return (
        <>
            <Spin spinning={profileImageLoading}>
                <div style={{
                    position: "relative",
                    width: 230,
                    height: 230,
                }}>

                    <img draggable={false} style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#F6F6F6',
                        borderRadius: 115,
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        objectFit: "contain"
                    }} src={userProfileImageUrl ? userProfileImageUrl : whizProfileDefaultImg}/>
                    <Upload {...uploadProps}>
                        <img draggable={false} style={{
                            position: "absolute",
                            cursor: "pointer",
                            right: 20,
                            bottom: 20,
                            zIndex: 10,
                        }} src={plusButtonIcon}/>
                    </Upload>
                </div>
            </Spin>
        </>
    );
}

export default UserProfileImageView;
