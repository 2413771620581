import _ from "lodash";

export function delay(ms: number): Promise<void> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, ms)
    })
}

export function debounce<Params extends any>(func: (arg: Params) => any, timeoutMilliSec: number,): (arg: Params) => void {
    let timer: NodeJS.Timeout
    return (arg: Params) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func(arg)
        }, timeoutMilliSec)
    }
}


export function throttle<T extends (...args: any) => any>(func: T, wait?: number, option: 'leading' | 'trailing' = 'leading'): _.DebouncedFunc<T> {
    return _.throttle(func, wait, {
        leading: option === 'leading',
        trailing: option === 'trailing'
    })
}