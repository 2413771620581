import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import User from "../../model/account/User";

interface State {
    user?: User
}

const userSlice = createSlice({
    name: 'user',
    initialState: {user: undefined} as State,
    reducers: {
        setUser: (state: State, action: PayloadAction<User | undefined>) => {
            state.user = action.payload
        }
    }
})

export const userAction = userSlice.actions
export const userReducer = userSlice.reducer
