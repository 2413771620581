import React, {useEffect, useState} from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import {Col, message, Row} from "antd";
import MyProfileView from "../user/MyProfileView";
import MyArticleTabView from "../user/MyArticleTabView";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import {useTranslation} from "react-i18next";
import UserApi from "../../api/manager/UserApi";
import {useAppDispatch, useAppSelector} from "../../reduxes/ReduxStore";
import {userAction} from "../../reduxes/slices/userSlice";

function MyPage(props: {}) {

    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false);
    const userInfo = useAppSelector(state => state.userReducer.user)

    useEffect(() => {

        setLoading(false)

        UserApi.getMe().then(res => {
            setLoading(false)

            // save
            if (res.data) dispatch(userAction.setUser(res.data))

        }).catchApi(e => {
            setLoading(false)
            message.error(e.msg)
        })

    }, [])

    return (
        <BasePageLayout>
            <div style={{marginTop: 47}}>
                <StyledSpan color={'Black'}
                            font={'RoboBold36'}>
                    {t('My Profile')}
                </StyledSpan>
            </div>
            <Row style={{marginTop: 27, marginBottom: 200}} wrap={false} gutter={[97, 97]}>
                <Col flex={'300px'}>
                    <MyProfileView userInfo={userInfo}/>
                </Col>
                <Col flex={"auto"}>
                    <MyArticleTabView/>
                </Col>
            </Row>
        </BasePageLayout>
    );
}

export default MyPage;
