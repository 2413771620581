import React from 'react';
import logoWithText from "../../assets/images/logo_with_text.svg";
import StyledDiv from "../base/styledcomponents/StyledDiv";
import {Input} from "antd";
import WhiteButton from "../base/WhiteButton";
import StyledSignBackground from "../base/styledcomponents/StyledSignBackground";
import {useTranslation} from "react-i18next";
import {theme} from "../../styles/theme";

function SignUpAuthCodeView(props: {
    phoneAuthCode: string, setPhoneAuthCode: React.Dispatch<string>
    onClickNext: () => void
}) {
    const {t} = useTranslation()

    return (
        <StyledSignBackground style={{display: "flex", alignItems: "center", flexDirection: "column", paddingTop: 80, height: '100%'}}>
            <img src={logoWithText}/>
            <div>
                <div style={{height: 85}}/>
                <StyledDiv font={'RoboBold32'} color={'White'}>{t('Enter authentication code')}</StyledDiv>
                <div style={{height: 8}}/>
                <StyledDiv font={'RoboRegular16'} color={'White'}>{t('Enter the 7-digit code we just sent you.')}</StyledDiv>
                <div style={{height: 50}}/>
                <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('Code')}</StyledDiv>
                <Input placeholder={t('7-digit code from SMS')!} bordered={true}
                       value={props.phoneAuthCode} onChange={event => props.setPhoneAuthCode(event.target.value)}
                       style={{width: 586, height: 55}}/>
            </div>
            <div style={{height: 24}}/>
            <StyledDiv font={'RoboRegular16'} color={'white'}>{t('Didn’t receive text?')}</StyledDiv>
            <StyledDiv font={'RoboRegular16'} color={theme.colors.Color4A90E2} style={{cursor: 'pointer'}}>{t('Resend the code')}</StyledDiv>

            <div style={{height: 144}}/>
            <WhiteButton font={'RoboBold20'} text={t('Continue')} style={{width: 586, height: 74}} onClick={props.onClickNext}/>
            <div style={{height: 80}}/>
        </StyledSignBackground>
    );
}

export default SignUpAuthCodeView;
