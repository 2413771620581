import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import "antd/dist/reset.css";
import App from './App';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import "./extdeclaration"
import {ThemeProvider} from "styled-components";
import {theme} from "./styles/theme";
import I18nUtil from "./translations/I18nUtil";
import {I18nextProvider} from "react-i18next";
import ReduxStore from './reduxes/ReduxStore'

ReactDOM.render(
    <Provider store={ReduxStore.store}>
            <CookiesProvider>
                <React.StrictMode>
                    <BrowserRouter>
                        <ThemeProvider theme={theme}>
                            <I18nextProvider i18n={I18nUtil.i18nInstance}>
                                <App/>
                            </I18nextProvider>
                        </ThemeProvider>
                    </BrowserRouter>
                </React.StrictMode>
            </CookiesProvider>
    </Provider>,
    document.getElementById('root')
);
