import React, {useState} from 'react';
import logoWithText from "../../assets/images/logo_with_text.svg";
import StyledDiv from "../base/styledcomponents/StyledDiv";
import {Input} from "antd";
import WhiteButton from "../base/WhiteButton";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import {theme} from "../../styles/theme";
import StyledSignBackground from "../base/styledcomponents/StyledSignBackground";
import {useTranslation} from "react-i18next";
import {AccountType} from "../../model/account/AccountType";

function SignUpEmailView(props: {
    accountType?: AccountType,
    firstName: string, setFirstName: React.Dispatch<string>,
    lastName: string, setLastName: React.Dispatch<string>,
    email: string, setEmail: React.Dispatch<string>,
    password: string, setPassword: React.Dispatch<string>,
    onClickNext: () => void
}) {
    const {t} = useTranslation()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [passwordInputState, setPasswordInputState] = useState<'error' | undefined>()

    return (
        <StyledSignBackground style={{display: "flex", alignItems: "center", flexDirection: "column", paddingTop: 80, height: '100%'}}>
            <img src={logoWithText}/>
            <div style={{height: 85}}/>
            <StyledDiv font={'RoboBold32'} color={'White'}>{t('Let’s set up your account')}</StyledDiv>
            <div style={{height: 78}}/>
            <div>
                <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('First Name')}</StyledDiv>
                <Input placeholder={t('First')!} style={{width: 586, height: 55}}
                       value={props.firstName} onChange={event => props.setFirstName(event.target.value)}/>
                <div style={{height: 12}}/>
                <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('Last Name')}</StyledDiv>
                <Input placeholder={t('Last')!} style={{width: 586, height: 55}}
                       value={props.lastName} onChange={event => props.setLastName(event.target.value)}/>
                <div style={{height: 12}}/>
                <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('Email')}</StyledDiv>
                <Input placeholder={t('you@example.com')!} style={{width: 586, height: 55}}
                       value={props.email} onChange={event => props.setEmail(event.target.value)}/>
                <div style={{height: 12}}/>
                {!props.accountType || props.accountType === AccountType.email ? <>
                    <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('Password')}</StyledDiv>
                    <Input.Password placeholder={t('Minimum 8 characters')!} style={{width: 586, height: 55}}
                                    visibilityToggle={{visible: passwordVisible, onVisibleChange: setPasswordVisible}}
                                    value={props.password}
                                    onChange={event => props.setPassword(event.target.value)}
                                    status={passwordInputState}/>
                </> : undefined
                }
                <div style={{height: 82}}/>
                <WhiteButton font={'RoboBold20'} text={t('Sign Up')} style={{width: 586, height: 74, fontWeight: 700}} onClick={props.onClickNext}/>
            </div>
            <div style={{height: 24}}/>
            <div>
                <StyledSpan font={'RoboRegular20'} color={'white'}>{t('Already have an account?')}</StyledSpan>

                <StyledSpan font={'RoboRegular20'} color={theme.colors.Color2F80ED} style={{marginLeft: 16, cursor: 'pointer'}}
                            onClick={() => window.location.replace('/sign-in')}>{t('Sign In')}</StyledSpan>
            </div>

            <div style={{height: 150}}/>
            <StyledDiv font={'RoboRegular18'} color={'white'}>{t('By signing up for our service, you agree to our')}<a href={'/policy'} target={'_blank'}
                                                                                                                       style={{color: theme.colors.Color2F80ED}}> {t('Privacy Policy and Terms of Service.')}</a></StyledDiv>
            <div style={{height: 80}}/>
        </StyledSignBackground>
    );
}

export default SignUpEmailView;
