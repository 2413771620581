import React, {useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import StyledSignBackground from "../base/styledcomponents/StyledSignBackground";
import logoWithText from "../../assets/images/logo_with_text.svg";
import StyledDiv from "../base/styledcomponents/StyledDiv";
import {Input, message, notification, Spin} from "antd";
import WhiteButton from "../base/WhiteButton";
import {theme} from "../../styles/theme";
import AccountApi from "../../api/manager/AccountApi";
import PasswordResetRequest from "../../model/sign/PasswordResetRequest";
import {useNavigate} from "react-router";

function PasswordFindPage(props: {}) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const handleClickFind = useCallback(() => {
        if (!email) {
            message.error('error.emailFormat')
            return
        }

        setLoading(true)
        AccountApi.requestPasswordReset(new PasswordResetRequest(email))
            .then(res => {
                setLoading(false)
                notification.open({
                    message: t('Please check your email'),
                    description: t('Password reset link has been sent'),
                    placement: "top"
                })
            })
            .catchApi(e => {
                setLoading(false)
                message.error(e.msg)
            })
    }, [email, t]);

    return (
        <Spin spinning={loading}>
            <StyledSignBackground style={{display: "flex", alignItems: "center", flexDirection: "column", paddingTop: 80, height: 'max(100vh, 100%)'}}>
                <img src={logoWithText}/>
                <div>
                    <div style={{height: 85}}/>
                    <StyledDiv font={'RoboBold32'} color={'White'}>{t('Forgot your Password?')}</StyledDiv>
                    <div style={{height: 9}}/>
                    <StyledDiv font={'RoboRegular16'}
                               color={'White'}>{t('Enter the email address for your account below. We will send you a link to reset your password.')}</StyledDiv>
                    <div style={{height: 51}}/>
                    <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('Email')}</StyledDiv>
                    <Input style={{width: 586, height: 55}} value={email} onChange={event => setEmail(event.target.value)}/>
                </div>

                <div style={{height: 82}}/>
                <WhiteButton text={t('Request Password Reset')} onClick={handleClickFind} style={{width: 586, height: 74}}/>
                <StyledDiv font={'RoboRegular20'} color={theme.colors.Color4A90E2} style={{marginTop: 24, cursor: 'pointer'}} onClick={() => {
                    navigate('/sign-start')
                }}>{t('Back to Sign In')}</StyledDiv>
                <div style={{height: 80}}/>
            </StyledSignBackground>
        </Spin>
    );
}

export default PasswordFindPage;
