import BaseResponse from "../../model/base/BaseResponse";
import ResultString from "../../model/base/ResultString";
import SnsSignInRequest from "../../model/sign/SnsSignInRequest";
import SnsSignUpRequest from "../../model/sign/SnsSignUpRequest";
import {request} from "../util/ConnectUtil";
import {HttpMethod} from "../util/HttpMethod";

async function snsSignIn(req: SnsSignInRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, '/social/signin', undefined, req)
}

async function snsSignUp(req: SnsSignUpRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, '/social/signup', undefined, req)
}

export default {
    snsSignIn, snsSignUp
}



