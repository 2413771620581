import React, {useState} from 'react';
import {message, Modal, Radio, RadioChangeEvent, Space} from "antd";
import StyledDiv from 'components/base/styledcomponents/StyledDiv';
import {useTranslation} from "react-i18next";
import {theme} from "../../styles/theme";
import {ProblemType} from "../../model/etc/ProblemType";
import TextArea from "antd/es/input/TextArea";
import ReportApi from "../../api/manager/ReportApi";
import ReportRequest from "../../model/etc/ReportRequest";

function ReportModal(props: { open: boolean, callback: () => void, targetId?: string }) {
    const {t} = useTranslation()

    const [loading, setLoading] = useState(false);
    const [selectedConcern, setSelectedConcern] = useState<ProblemType>();
    const [comment, setComment] = useState('');

    const onConcernChange = (e: RadioChangeEvent) => {
        setSelectedConcern(e.target.value);
    };

    const handleOk = () => {
        if (!selectedConcern || !comment) {
            message.error(t('error.inputAll'))
            return
        }
        setLoading(true)
        ReportApi.report(new ReportRequest(props.targetId ?? '', selectedConcern, comment))
            .then(res => {
                setLoading(false)
                props.callback()
            })
            .catchApi(e => {
                setLoading(false)
                message.error(e.msg)
            })
    }

    const handleCancel = () => {
        props.callback()
    }

    return (
        <Modal open={props.open} okText={t('Report')} cancelText={t('Cancel')} onOk={handleOk} onCancel={handleCancel} confirmLoading={loading}>
            <StyledDiv font={'RoboBold24'}>{t('ReportTitle')}</StyledDiv>
            <StyledDiv font={'RoboRegular14'}
                       color={theme.colors.ColorBDBDBD}
                       style={{marginTop: 13}}>{t('Your satisfactory experience is our top priority. If you see/experience any concern regarding the content, please share it with  us.')}</StyledDiv>
            <div style={{paddingLeft: 24, paddingRight: 24}}>
                <StyledDiv font={'RoboBold16'}
                           style={{marginTop: 32, marginBottom: 16}}>{t('Select a concern type')}</StyledDiv>
                <Radio.Group onChange={onConcernChange} value={selectedConcern}>
                    <Space direction="vertical">
                        <Radio value={ProblemType.technical}>{t('Technical Difficulty/Issue')}</Radio>
                        <Radio value={ProblemType.inappropriate_contents}>{t('Inappropriate Content')}</Radio>
                        <Radio value={ProblemType.misinfomation}>{t('Misinformation/Falsification')}</Radio>
                        <Radio value={ProblemType.mistranslation}>{t('Mistranslation')}</Radio>
                    </Space>
                </Radio.Group>
                <StyledDiv font={'RoboBold14'}
                           style={{marginTop: 64}}>{t('Please describe details')}</StyledDiv>
                <TextArea rows={4} value={comment} onChange={event => setComment(event.target.value)} style={{marginTop: 12}}/>
                <div style={{height: 32}}/>
            </div>


        </Modal>
    );
}

export default ReportModal;
