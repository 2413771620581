import Topic from "../../model/counsel/Topic";
import BaseListResponse from "../../model/base/BaseListResponse";
import Language from "../../model/account/Language";
import {request} from "../util/ConnectUtil";
import {HttpMethod} from "../util/HttpMethod";
import LocalStorageUtil from "../../util/LocalStorageUtil";
import Lodash from "../../util/Lodash";
import DateUtil from "../../util/DateUtil";

const WEB_TOPIC_CACHE_KEY = 'aw_webTopicK'
type TopicCachedData = {
    expireAt?: number,
    topicList?: Topic[]
}

async function getTopics(): Promise<Topic[]> {
    const cachedData: TopicCachedData = JSON.parse(LocalStorageUtil.getItem(WEB_TOPIC_CACHE_KEY, '{}'))
    try {
        if (!cachedData?.expireAt || cachedData.expireAt <= Date.now() || Lodash.isEmpty(cachedData.topicList)) {
            //get from api
            const apiResponse = await request<BaseListResponse<Topic>>(HttpMethod.GET, '/preferences/web_topic')
            if (apiResponse.list && apiResponse.list.length > 0) {
                LocalStorageUtil.setItem(WEB_TOPIC_CACHE_KEY, JSON.stringify({
                    expireAt: Date.now() + DateUtil.DAY,
                    topicList: apiResponse.list
                }))
            }
            return apiResponse.list ?? []
        } else {
            return cachedData.topicList ?? []
        }
    } catch (e: any) {
        console.error(e)
        return cachedData.topicList ?? []
    }
}

async function getTopic(topicId?: string | null): Promise<Topic | undefined> {
    if (!topicId) return undefined
    return (await getTopics()).find(t => t.id === topicId)
}

async function getLanguages(): Promise<BaseListResponse<Language>> {
    return await request(HttpMethod.GET, '/preferences/language')
}

export default {
    getTopics, getTopic, getLanguages
}
