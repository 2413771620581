import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Divider, message, Row} from "antd";
import {useNavigate} from "react-router";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import styled from "styled-components";
import AnswerDetailHeaderView from "../question/view/AnswerDetailHeaderView";
import HtmlContentEditView from "../content/HtmlContentEditView";
import ContentsApi from "../../api/manager/ContentsApi";
import PostAnswerRequest from "../../model/question/PostAnswerRequest";
import I18nUtil from "../../translations/I18nUtil";
import {useTranslation} from "react-i18next";
import ContentInfo from "../../model/question/ContentInfo";
import {getTransMapData} from "../../model/question/TranslateInfo";


const ContentContainer = styled.div`
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 24px 35px 24px 35px;
`

function CreateAnswerView(props: { questionId: string, editAnswer?: ContentInfo }) {

    const navigate = useNavigate();
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false);
    const [html, setHtml] = useState('');

    useEffect(() => {
        if (!props.editAnswer) return
        setHtml(getTransMapData(props.editAnswer.descHtml) ?? '')
    }, [props.editAnswer]);

    const handleHtmlChange = useCallback((html: string) => {
        setHtml(html)
    }, []);

    const handleCreateAnswer = useCallback(() => {
        if (!html) {
            message.error(t('error.inputAll'))
            return
        }

        setLoading(true)
        const req = new PostAnswerRequest(html, I18nUtil.getLanguage())
        const api = props.editAnswer ? ContentsApi.putAnswer(props.questionId, props.editAnswer._id, req) :
            ContentsApi.postAnswer(props.questionId, req)

        api.then(res => {
            setLoading(false)
            navigate(-1)
        }).catchApi(e => {
            setLoading(false)
            message.error(e.msg)
        })
    }, [props.questionId, html, t])

    return (
        <div>
            <StyledSpan color={'Black'}
                        font={'RoboBold20'}>
                {t('Answer')}
            </StyledSpan>
            <ContentContainer style={{marginTop: 12}}>
                <AnswerDetailHeaderView hideAction={true}/>
                <Divider style={{marginTop: 12, marginBottom: 32}}/>
                <HtmlContentEditView initialHtml={html} onChange={handleHtmlChange}/>
                <div style={{marginTop: 12}}>
                    <Row>
                        <Col flex={"auto"}/>
                        <Col>
                            <StyledSpan color={'Black'}
                                        font={'RoboRegular14'}>
                                {t('It may take up to 1-2 business days to complete registration')}
                            </StyledSpan>
                        </Col>
                    </Row>
                </div>
            </ContentContainer>
            <div style={{marginTop: 12}}>
                <Row>
                    <Col flex={"auto"}/>
                    <Col>
                        <Button type={"primary"}
                                style={{width: 176}}
                                size={"large"}
                                loading={loading}
                                onClick={handleCreateAnswer}>
                            {t('Submit Answer')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default CreateAnswerView;
