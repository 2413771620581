import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Divider, message, Row, Spin} from "antd";
import {useNavigate} from "react-router";
import styled from "styled-components";
import CreateQuestionUserInfoView from "./create/CreateQuestionUserInfoView";
import CreateQuestionTitleView from "./create/CreateQuestionTitleView";
import CreateQuestionCategoryView from "./create/CreateQuestionCategoryView";
import CreateQuestionContentView from "./create/CreateQuestionContentView";
import ContentsApi from "../../api/manager/ContentsApi";
import PostQuestionRequest from "../../model/question/PostQuestionRequest";
import I18nUtil from "../../translations/I18nUtil";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import ContentInfo from "../../model/question/ContentInfo";
import {getTransMapData} from "../../model/question/TranslateInfo";
import {useAppSelector} from "../../reduxes/ReduxStore";

const ContentContainer = styled.div`
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 24px 35px 24px 35px;
`

function CreateQuestionView(props: {}) {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false);
    const [categoryId, setCategoryId] = useState<string>('');
    const [title, setTitle] = useState('');
    const [html, setHtml] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [editQuestion, setEditQuestion] = useState<ContentInfo>();
    const userInfo = useAppSelector(state => state.userReducer.user)

    useEffect(() => {
        const editQuestionId = searchParams.get('id')
        if (editQuestionId) {
            setLoading(true)
            ContentsApi.getContents(editQuestionId)
                .then(res => {
                    setLoading(false)
                    if (res.data) {
                        setEditQuestion(res.data)
                        setCategoryId(res.data.topicId ?? '')
                        setTitle(getTransMapData(res.data.title) ?? '')
                        setHtml(getTransMapData(res.data.descHtml) ?? '')
                    }
                })
                .catchApi(e => {
                    setLoading(false)
                    message.error(e.msg)
                    console.error(e)
                })
        }
    }, [searchParams]);

    const handleCategoryChange = useCallback((topicId: string) => {
        setCategoryId(topicId)
    }, []);

    const handleCreateQuestion = useCallback(() => {

        if (!title || !html || !categoryId) {
            message.error(t('error.inputAll'))
            return
        }
        setLoading(true)
        const api = editQuestion ?
            ContentsApi.putQuestion(editQuestion._id, new PostQuestionRequest(html, I18nUtil.getLanguage(), title, categoryId)) :
            ContentsApi.postQuestion(new PostQuestionRequest(html, I18nUtil.getLanguage(), title, categoryId))

        api.then(res => {
            setLoading(false)
            navigate(-1)
        }).catchApi(e => {
            setLoading(false)
            message.error(e.msg)
        })

    }, [navigate, title, html, categoryId])

    return (
        <Spin spinning={loading}>
            <div>
                <ContentContainer>
                    <CreateQuestionUserInfoView user={userInfo}/>
                    <Divider style={{marginTop: 12, marginBottom: 22}}/>
                    <CreateQuestionTitleView title={title} setTitle={setTitle}/>
                    <div style={{marginTop: 22}}>
                        <CreateQuestionCategoryView defaultCategoryId={categoryId}
                                                    onCategoryChange={handleCategoryChange}/>
                    </div>
                    <div style={{marginTop: 22, marginBottom: 24}}>
                        <CreateQuestionContentView html={html} setHtml={setHtml}/>
                    </div>
                </ContentContainer>
                <div style={{marginTop: 12}}>
                    <Row>
                        <Col flex={"auto"}/>
                        <Col>
                            <Button type={"primary"}
                                    style={{width: 176}}
                                    size={"large"}
                                    onClick={handleCreateQuestion}>
                                {t('Submit Question')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Spin>
    );
}

export default CreateQuestionView;
