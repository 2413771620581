import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import ContentInfo from "../../model/question/ContentInfo";
import ContentsApi from "../../api/manager/ContentsApi";
import {Button, Col, Divider, message, Row, Spin} from "antd";
import TranslateInfo, {getTransMapData} from "../../model/question/TranslateInfo";
import CreateQuestionUserInfoView from "../../components/question/create/CreateQuestionUserInfoView";
import CreateQuestionTitleView from "../../components/question/create/CreateQuestionTitleView";
import CreateQuestionCategoryView from "../../components/question/create/CreateQuestionCategoryView";
import CreateQuestionContentView from "../../components/question/create/CreateQuestionContentView";
import styled from "styled-components";
import User from "../../model/account/User";
import UserApi from "../../api/manager/UserApi";
import {LocateType} from "../../model/question/LocateType";
import AdminContentsApi from "../api/manager/AdminContentsApi";
import AdminContentsRequest from "../model/AdminPutQuestionRequest";

const ContentContainer = styled.div`
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 24px 50px 24px 50px;
`

function AdminQuestionDetailPage(props: {}) {

    const navigate = useNavigate();
    const {id} = useParams();
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false);
    const [editQuestion, setEditQuestion] = useState<ContentInfo>();
    const [createdBy, setCreatedBy] = useState<User>();
    const [categoryId, setCategoryId] = useState<string>('');

    //KR
    const [koTitle, setKoTitle] = useState('');
    const [koHtml, setKoHtml] = useState('');

    //EN
    const [enTitle, setEnTitle] = useState('');
    const [enHtml, setEnHtml] = useState('');

    useEffect(() => {
        if (!id) return
        setLoading(true)
        ContentsApi.getContents(id)
            .then(res => {
                if (res.data) {
                    setEditQuestion(res.data)
                    setCategoryId(res.data.topicId ?? '')
                    setKoTitle(getTransMapData(res.data.title, LocateType.ko) ?? '')
                    setKoHtml(getTransMapData(res.data.descHtml, LocateType.ko) ?? '')
                    setEnTitle(getTransMapData(res.data.title, LocateType.en) ?? '')
                    setEnHtml(getTransMapData(res.data.descHtml, LocateType.en) ?? '')
                }
                return UserApi.getUser(res.data!.createdBy!._id)
            })
            .then(res => {
                setLoading(false)
                setCreatedBy(res.data)
            })
            .catchApi(e => {
                setLoading(false)
                message.error(e.msg)
                console.error(e)
            })
    }, [id]);

    const handleCategoryChange = useCallback((topicId: string) => {
        setCategoryId(topicId)
    }, []);

    const handleEditQuestion = useCallback(() => {
        if (!editQuestion) return

        if (!koTitle || !koHtml || !enTitle || !enHtml || !categoryId) {
            message.error(t('error.inputAll'))
            return
        }
        setLoading(true)

        AdminContentsApi.putContents(editQuestion._id, new AdminContentsRequest(
            categoryId,
            new TranslateInfo<string>(editQuestion.question?.title?.oriLocate ?? LocateType.ko, {ko: koTitle, en: enTitle}),
            new TranslateInfo<string>(editQuestion.question?.descHtml?.oriLocate ?? LocateType.ko, {ko: koHtml, en: enHtml}),
        )).then(res => {
            setLoading(false)
            navigate(-1)
        }).catchApi(e => {
            setLoading(false)
            message.error(e.msg)
        })

    }, [navigate, koTitle, koHtml, enTitle, enHtml, categoryId, editQuestion])

    return (
        <Spin spinning={loading}>
            <div style={{padding: 24}}>
                <div style={{fontSize: 48, marginBottom: 24, background: 'gray'}}>관리자 질문 수정</div>

                {/*KO*/}
                <div style={{fontSize: 48, marginBottom: 12}}>한국어</div>
                <ContentContainer>
                    <CreateQuestionUserInfoView user={createdBy}/>
                    <Divider style={{marginTop: 12, marginBottom: 22}}/>
                    <CreateQuestionTitleView title={koTitle} setTitle={setKoTitle}/>
                    <div style={{marginTop: 22}}>
                        <CreateQuestionCategoryView defaultCategoryId={categoryId} onCategoryChange={handleCategoryChange}/>
                    </div>
                    <div style={{marginTop: 22, marginBottom: 24}}>
                        <CreateQuestionContentView html={koHtml} setHtml={setKoHtml}/>
                    </div>
                </ContentContainer>
                {/*EN*/}
                <div style={{fontSize: 48, marginBottom: 12, marginTop: 36}}>English</div>
                <ContentContainer>
                    <CreateQuestionUserInfoView user={createdBy}/>
                    <Divider style={{marginTop: 12, marginBottom: 22}}/>
                    <CreateQuestionTitleView title={enTitle} setTitle={setEnTitle}/>
                    <div style={{marginTop: 22}}>
                        <CreateQuestionCategoryView defaultCategoryId={categoryId} onCategoryChange={handleCategoryChange}/>
                    </div>
                    <div style={{marginTop: 22, marginBottom: 24}}>
                        <CreateQuestionContentView html={enHtml} setHtml={setEnHtml}/>
                    </div>
                </ContentContainer>
                <div style={{marginTop: 12}}>
                    <Row>
                        <Col flex={"auto"}/>
                        <Col>
                            <Button type={"primary"}
                                    style={{width: 176}}
                                    size={"large"}
                                    onClick={handleEditQuestion}>
                                {'질문 수정하기'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Spin>
    );
}

export default AdminQuestionDetailPage;
