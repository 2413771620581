/**
 * CKEditor 는 typescript 에서 쓰기 어려움 so, js 로 만들어서, typescript 로 Wrapping
 * CKEditor 를 이용하는 방법은 다양한데, 여러가지 webpack 설정들도 들어가야 해서,.. 가장 간단한 Online Builder 로 만든다음, 별도 module 로 넣어서 사용하는 방법 사용
 * package.json 에 "ckeditor5-custom-build": "file:ckeditor" 으로 모듈 추가함
 * Integrating a build from the online builder (https://ckeditor.com/docs/ckeditor5/latest/installation/getting-started/frameworks/react.html#using-create-react-app1)
 */

/**
 * todo: font 종류 커스텀 가능? (무료만 사용가능)
 */
import React, {Component} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {Editor as ClassicEditor} from 'ckeditor5-custom-build/build/ckeditor';
// import {Editor as BalloonEditor} from 'ckeditor5-custom-build/build/ckeditor';
import PropTypes from "prop-types";
import WysiwygUploadAdapter from "../util/WysiwygUploadAdapter";

class WysiwygUploadAdapterPlugin {
    constructor(editor) {
        this.editor = editor
    }

    init() {
        const url = this.editor.config.get('wysiwygUploadAdapter.imageUploadUrlPath');

        if (!url) return

        this.editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new WysiwygUploadAdapter(loader, url);
        };
    }

}

class CKEditorJS extends Component {


    CKEditorConfig = {
        // toolbar: ['bold', 'italic']
        extraPlugins: [WysiwygUploadAdapterPlugin],
        wysiwygUploadAdapter: {
            imageUploadUrlPath: this.props.imageUploadUrlPath
        },
        //ckeditor.js 에서 해도 되나, custom style 을 여기에 갖고있어서 여기에 있는게 더 reasonable 함
        //h1, h2 등으로 할 때, polaris css 에서 h1, h2 태그등의 fontsize 를 고정시켜버려서 custom style 로 만듦
        heading: {
            options: [
                {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                {
                    model: 'percentyCustomHeading1',
                    view: {
                        name: 'p',
                        classes: ['wysiwyg-custom-heading-view1']
                    },
                    title: 'Heading 1',
                    class: 'custom-heading1',
                    converterPriority: 'high'
                },
                {
                    model: 'percentyCustomHeading2',
                    view: {
                        name: 'p',
                        classes: ['wysiwyg-custom-heading-view2']
                    },
                    title: 'Heading 2',
                    class: 'custom-heading2',
                    converterPriority: 'high'
                },
                {
                    model: 'percentyCustomHeading3',
                    view: {
                        name: 'p',
                        classes: ['wysiwyg-custom-heading-view3']
                    },
                    title: 'Heading 3',
                    class: 'custom-heading3',
                    converterPriority: 'high'
                },
                // {model: 'heading1', view: 'p', title: 'Heading 1', class: 'custom-heading1'},
                // {model: 'heading2', view: 'p', title: 'Heading 2', class: 'custom-heading2'},
                // {model: 'heading3', view: 'p', title: 'Heading 3', class: 'custom-heading3'},
            ]
        }
    }

    render() {
        return (
            <div>
                <CKEditor
                    editor={ClassicEditor}
                    config={this.CKEditorConfig} //build 된 ckeditor 사용해도 toolbar 는 custom 가능
                    data={this.props.data}
                    onReady={this.props.onReady}//Editor is ready to use
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    onFocus={this.props.onFocus}
                />
            </div>
        );
    }
}

CKEditorJS.propTypes = {
    imageUploadUrlPath: PropTypes.string,
    data: PropTypes.string,
    onReady: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
}

export default CKEditorJS;

