import BaseResponse from "../../model/base/BaseResponse";
import ResultString from "../../model/base/ResultString";
import {request} from "../util/ConnectUtil";
import {HttpMethod} from "../util/HttpMethod";
import ContentInfo from "../../model/question/ContentInfo";
import BaseListResponse from "../../model/base/BaseListResponse";

async function postAnswerLike(answerId: string): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, `/answers/${answerId}/like`)
}

async function deleteAnswerLike(answerId: string): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.DELETE, `/answers/${answerId}/like`)
}

async function getAnswerLike(answerId: string): Promise<BaseResponse<boolean>> {
    return await request(HttpMethod.GET, `/answers/${answerId}/like`)
}

async function getMyLikedContents(query?: {
    withCount?: boolean,
    indexOfPage?: number,
    countInPage?: number
}): Promise<BaseListResponse<ContentInfo>> {
    return await request(HttpMethod.GET, `/me/likes`, query)
}

export default {
    postAnswerLike, deleteAnswerLike, getMyLikedContents, getAnswerLike
}
