export interface IEvent {
}

export class EventExcelUploadSuccess implements IEvent {

}

export class EventSettingsModified implements IEvent {

    reportMonthPeriod?: number

    constructor(reportMonthPeriod?: number) {
        this.reportMonthPeriod = reportMonthPeriod;
    }
}

export class LanguageChangeEvent implements IEvent {
}

export class EventCustomMarketingDataUploadSuccess implements IEvent {
    constructor(public readonly isEdit?: boolean) {
    }
}

export class EventAnswerDeleted implements IEvent {
    constructor(public readonly answerId?: string) {
    }

}
