import React from 'react';
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import {Input} from "antd";
import {useTranslation} from "react-i18next";

function CreateQuestionTitleView(props: {
    title: string, setTitle: React.Dispatch<string>
}) {
    const {t} = useTranslation()

    return (
        <>
            <StyledSpan color={'Black'}
                        font={'RoboMedium20'}>
                {t('Title')}
            </StyledSpan>
            <div style={{marginTop: 8}}>
                <Input size="large"
                       placeholder=""
                       value={props.title}
                       onChange={event => props.setTitle(event.target.value)}/>
            </div>
        </>
    );
}

export default CreateQuestionTitleView;
