import React, {useCallback, useEffect, useState} from 'react';
import logoWithText from '../../assets/images/logo_with_text.svg'
import StyledSignBackground from "../base/styledcomponents/StyledSignBackground";
import {useTranslation} from "react-i18next";
import StyledDiv from "../base/styledcomponents/StyledDiv";
import facebookIcon from "../../assets/images/facebook_icon.svg"
import googleIcon from "../../assets/images/google_icon.svg"
import WhiteButton from "../base/WhiteButton";
import StyledSpan from 'components/base/styledcomponents/StyledSpan';
import {theme} from "../../styles/theme";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import ApiConf from "../../api/util/ApiConf";
import {ReactFacebookFailureResponse, ReactFacebookLoginInfo} from "react-facebook-login";
import GoogleLogin, {GoogleLoginResponse, GoogleLoginResponseOffline} from "react-google-login";
import {gapi} from "gapi-script";
import SocialApi from "../../api/manager/SocialApi";
import SnsSignInRequest from "../../model/sign/SnsSignInRequest";
import DeviceUtil from "../../util/DeviceUtil";
import CookieManager from "../../util/CookieManager";
import UserApi from "../../api/manager/UserApi";
import {message, Spin} from "antd";
import CryptoUtil from "../../util/CryptoUtil";
import {AccountType, accountTypeToSnsProvider} from "../../model/account/AccountType";
import {useAppDispatch} from "../../reduxes/ReduxStore";
import {userAction} from "../../reduxes/slices/userSlice";
import {useNavigate} from "react-router";

function SignStartPage(props: {}) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        initGoogleOAuth()
    }, [])

    function initGoogleOAuth() {
        gapi.load("client:auth2", () => {
            gapi.client.init({clientId: ApiConf.googleIdentityClientId, plugin_name: "chat",});
        });
    }

    const handleFacebookLogin = useCallback((res: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
        // @ts-ignore
        if (res.accessToken) {
            const temp = res as ReactFacebookLoginInfo
            snsSignInOrSignUp(AccountType.facebook, temp.accessToken, temp.email ?? '')
        } else {
            message.error('정보를 가져오는데 실패했습니다.')
        }

    }, [])

    const handleGoogleLogin = useCallback((res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        // @ts-ignore
        if (res.accessToken) {
            const temp = res as GoogleLoginResponse
            snsSignInOrSignUp(AccountType.google, temp.tokenObj.id_token, temp.profileObj?.email ?? '')
        } else {
            message.error('정보를 가져오는데 실패했습니다.')
        }
    }, [])

    const handleClickEmailSignUp = useCallback(() => {
        navigate(`/sign-up?param=${CryptoUtil.encodeBase64(JSON.stringify({accountType: AccountType.email,}))}`)
    }, []);

    function snsSignInOrSignUp(accountType: AccountType, snsToken: string, email: string) {
        setLoading(true)
        SocialApi.snsSignIn(new SnsSignInRequest(DeviceUtil.getDeviceUuid(), accountTypeToSnsProvider(accountType)!, snsToken))
            .then(res => {
                CookieManager.setCookie(CookieManager.CookieKey.AccessToken, res.data?.result ?? '')
                return UserApi.getMe()
            })
            .then(res => {
                setLoading(false)
                dispatch(userAction.setUser(res.data!))
                navigate('/')
            })
            .catchApi(e => {
                setLoading(false)
                if (e.code === -1001) {
                    navigate(`/sign-up?param=${CryptoUtil.encodeBase64(JSON.stringify({
                        accountType: accountType,
                        snsToken: snsToken,
                        email: email
                    }))}`)
                } else {
                    console.error(e)
                    message.error(e.msg)
                }
            })
    }


    return (
        <Spin spinning={loading}>
            <StyledSignBackground style={{display: "flex", alignItems: "center", flexDirection: "column", paddingTop: 80, height: '100%'}}>
                <img src={logoWithText}/>
                <div style={{height: 85}}/>
                <StyledDiv font={'RoboBold32'} color={'White'}>{t('Let’s get started')}</StyledDiv>
                <div style={{height: 78}}/>

                <FacebookLogin appId={ApiConf.facebookAppId} autoLoad={false} fields={'name,email'} callback={handleFacebookLogin} render={prop =>
                    (<WhiteButton text={t('Continue with Facebook')} icon={facebookIcon} onClick={prop.onClick} style={{width: 586, height: 74}}/>)
                }/>
                <div style={{height: 16}}/>
                <GoogleLogin
                    clientId={ApiConf.googleIdentityClientId}
                    render={renderProps => (
                        <WhiteButton text={t('Continue with Google')} icon={googleIcon} style={{width: 586, height: 74}} onClick={renderProps.onClick}/>
                    )}
                    onSuccess={handleGoogleLogin}
                    onFailure={res => console.error(res)}
                    cookiePolicy={'single_host_origin'}
                />
                {/*<div style={{height: 16}}/>*/}
                {/*<WhiteButton text={t('Continue with Apple')} icon={appleIcon} style={{width: 586, height: 74}}/>*/}
                <div style={{height: 63}}/>
                <div style={{display: "flex", width: 586, alignItems: "center"}}>
                    <div style={{height: 1, backgroundColor: "white", flexGrow: 1}}/>
                    <StyledSpan font={'RoboBold16'} color={'white'} style={{marginLeft: 19, marginRight: 19}}>{t('OR')}</StyledSpan>
                    <div style={{height: 1, backgroundColor: "white", flexGrow: 1}}/>
                </div>
                <div style={{height: 63}}/>
                <WhiteButton text={t('Continue with Email')} style={{width: 586, height: 74}} onClick={handleClickEmailSignUp}/>
                <div style={{height: 24}}/>
                <div>
                    <StyledSpan font={'RoboRegular20'} color={'white'}>{t('Already have an account?')}</StyledSpan>

                    <StyledSpan font={'RoboRegular20'} color={theme.colors.Color2F80ED} style={{marginLeft: 16, cursor: 'pointer'}}
                                onClick={() => navigate('/sign-in')}>{t('Sign In')}</StyledSpan>
                </div>
                <div style={{height: 150}}/>
                <StyledDiv font={'RoboRegular18'} color={'white'}>{t('By signing up for our service, you agree to our')}<a href={'/policy'} target={'_blank'}
                                                                                                                           style={{color: theme.colors.Color2F80ED}}> {t('Privacy Policy and Terms of Service.')}</a></StyledDiv>
                <div style={{height: 80}}/>
            </StyledSignBackground>
        </Spin>

    );
}

export default SignStartPage;
