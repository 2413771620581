import {SnsProvider} from "./SnsProvider";

export default class SnsSignInRequest {
    constructor(
        public readonly deviceUuid: string,
        public readonly provider: SnsProvider,
        public readonly token: string
    ) {
    }
}
