import React, {useEffect, useState} from 'react';
import {Button, Col, Image, Layout, Row, Select} from 'antd';
import {Link} from "react-router-dom";
import logoWithText from "../../../assets/images/logo_with_text.svg"
import StyledSpan from "../styledcomponents/StyledSpan";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import facebookIcon from "../../../assets/images/img_sns_icon_facebook.svg"
import inIcon from "../../../assets/images/img_sns_icon_in.svg"
import instagramIcon from "../../../assets/images/img_sns_icon_Instagram.svg"
import twitterIcon from "../../../assets/images/img_sns_icon_twitter.svg"
import CookieManager from "../../../util/CookieManager";
import I18nUtil from "../../../translations/I18nUtil";
import {LocateType} from "../../../model/question/LocateType";
import {useNavigate} from "react-router";

const {Header, Content, Footer} = Layout;

const {Option} = Select;

const LoginButtonContainer = styled.div`
  button {
    background: #1A356C;
    border-radius: 100px;
    color: white;
    border-width: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    padding-left: 30px;
    padding-right: 30px;
  }
`

const CountrySelectContainer = styled.div`
  .ant-select .ant-select-selector {
    background-color: unset;
    border: unset;
  }

  .ant-select .ant-select-selector .ant-select-selection-item {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }

  .ant-select .ant-select-arrow {
    color: white;
  }

`

function BasePageLayout(props: { showCompanyInfo?: boolean, children?: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {

    // TODO
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [token, setToken] = useState(CookieManager.getCookie(CookieManager.CookieKey.AccessToken));

    useEffect(() => {

    }, [])

    const handleLanguageChange = (value: string) => {
        I18nUtil.setLanguage(value as LocateType)
    };

    // href={'/mypage'}

    return (
        <Layout className="layout" style={{...props.style, minWidth: 1000, minHeight: '100vh'}}>
            <Header>
                <Row gutter={[50, 50]}>
                    <Col flex={"none"}>
                        <Link to={'/'}>
                            <Image preview={false}
                                   width={147}
                                   src={logoWithText}/>
                        </Link>
                    </Col>
                    <Col flex={"auto"}>
                    </Col>
                    <Col flex={"none"}>
                        <StyledSpan style={{textDecoration: "underline"}}
                                    color={'White'}
                                    font={'RoboBold24'}>
                            {'Lite'}
                        </StyledSpan>
                    </Col>
                    <Col flex={"none"}>
                        <LoginButtonContainer>
                            <Button onClick={event => {
                                token ? navigate('/mypage') : navigate('/sign-start')
                            }}>
                                {token ? t('My Page') : t('Log in/Sign up')}
                            </Button>
                        </LoginButtonContainer>
                    </Col>
                    <Col flex={"none"}>
                        <CountrySelectContainer>
                            <Select
                                defaultValue={I18nUtil.getLanguage()}
                                style={{width: 120}}
                                onChange={handleLanguageChange}>
                                <Option value="en" label="English">
                                    <div className="demo-option-label-item">
                                    <span role="img" aria-label="USA">
                                      🇺🇸
                                    </span>
                                        {' English'}
                                    </div>
                                </Option>
                                <Option value="ko" label="Korean">
                                    <div className="demo-option-label-item">
                                    <span role="img" aria-label="Korea">
                                      🇰🇷
                                    </span>
                                        {' Korean'}
                                    </div>
                                </Option>
                            </Select>
                        </CountrySelectContainer>
                    </Col>
                </Row>
            </Header>
            <Content style={{background: "white", padding: '0 150px'}}>
                {props.children}
            </Content>
            <Footer>
                <Row align={"middle"} gutter={[50, 50]}>
                    <Col flex={"none"}>
                        <StyledSpan color={'Black3F3F3F'}
                                    font={'RoboRegular14'}>
                            {'©2022 AllWhiz, Inc. All rights reserved'}
                        </StyledSpan>
                    </Col>
                    <Col flex={"auto"}/>
                    <Col onClick={event => navigate('/about')} style={{cursor: "pointer"}}>
                        <StyledSpan color={'Color898A8D'}
                                    font={'RoboRegular14'}>
                            {'About'}
                        </StyledSpan>
                    </Col>
                    <Col onClick={event => navigate('/platform')} style={{cursor: "pointer"}}>
                        <StyledSpan color={'Color898A8D'}
                                    font={'RoboRegular14'}>
                            {'Platform'}
                        </StyledSpan>
                    </Col>
                    <Col onClick={event => navigate('/policy')} style={{cursor: "pointer"}}>
                        <StyledSpan color={'Color898A8D'}
                                    font={'RoboRegular14'}>
                            {'Policy'}
                        </StyledSpan>
                    </Col>
                    <Col onClick={event => navigate('/contact')} style={{cursor: "pointer"}}>
                        <StyledSpan color={'Color898A8D'}
                                    font={'RoboRegular14'}>
                            {'Contact'}
                        </StyledSpan>
                    </Col>
                    <Col>
                        <Row align={"middle"} gutter={[15, 15]}>
                            <Col onClick={event => window.open('https://www.facebook.com/AllWhiz', '_blank')}
                                 style={{cursor: 'pointer'}}>
                                <Image preview={false}
                                       width={30}
                                       src={facebookIcon}/>

                            </Col>
                            <Col onClick={event => window.open('https://twitter.com/AllWhizInc', '_blank')}
                                 style={{cursor: 'pointer'}}>
                                <Image preview={false}
                                       width={30}
                                       src={twitterIcon}/>
                            </Col>
                            <Col onClick={event => window.open('https://www.instagram.com/allwhizinc/', '_blank')}
                                 style={{cursor: 'pointer'}}>
                                <Image preview={false}
                                       width={30}
                                       src={instagramIcon}/>
                            </Col>
                            <Col onClick={event => window.open('https://www.linkedin.com/company/allwhiz', '_blank')}
                                 style={{cursor: 'pointer'}}>
                                <Image preview={false}
                                       width={30}
                                       src={inIcon}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Footer>
            {props.showCompanyInfo && I18nUtil.getLanguage() === LocateType.ko ? <>
                <Footer>
                    <div style={{marginBottom: 4}}>주식회사 올위즈코리아</div>
                    <div style={{marginBottom: 4}}>사업자등록번호 194-88-02451</div>
                    <div style={{marginBottom: 4}}>서울특별시 광진구 광나루로 478, 로비101(구의동, 광진 경제 허브센터 도약관)</div>
                    <div style={{}}>대표이사 김태근</div>
                </Footer>
            </> : undefined
            }
        </Layout>
    );
}

export default BasePageLayout;
