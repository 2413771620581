import React from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import CreateQuestionView from "../question/CreateQuestionView";
import BackButtonView from "../question/BackButtonView";
import {useTranslation} from "react-i18next";

function QuestionWritePage(props: {}) {

    const {t} = useTranslation()

    return (
        <BasePageLayout>
            <div style={{marginTop: 64}}>
                <BackButtonView title={`${t('Ask Question')}`}/>
            </div>
            <div style={{marginTop: 32, marginBottom: 300}}>
                <CreateQuestionView/>
            </div>
        </BasePageLayout>
    );
}

export default QuestionWritePage;
