import React, {useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import StyledSignBackground from "../base/styledcomponents/StyledSignBackground";
import logoWithText from "../../assets/images/logo_with_text.svg";
import StyledDiv from "../base/styledcomponents/StyledDiv";
import {Input, message, Spin} from "antd";
import {theme} from "../../styles/theme";
import WhiteButton from "../base/WhiteButton";
import AccountApi from "../../api/manager/AccountApi";
import EmailSignInRequest from "../../model/sign/EmailSignInRequest";
import DeviceUtil from "../../util/DeviceUtil";
import CookieManager from "../../util/CookieManager";
import UserApi from "../../api/manager/UserApi";
import {useAppDispatch} from "../../reduxes/ReduxStore";
import {userAction} from "../../reduxes/slices/userSlice";
import {useNavigate} from "react-router";

function SignInPage(props: {}) {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false)
    const navigate = useNavigate()

    const handleClickSignIn = useCallback(() => {
        setLoading(true)
        AccountApi.emailSignIn(new EmailSignInRequest(email, password, DeviceUtil.getDeviceUuid()))
            .then(res => {
                if (res.data?.result) CookieManager.setCookie(CookieManager.CookieKey.AccessToken, res.data?.result)
                return UserApi.getMe()
            })
            .then(res => {
                setLoading(false)
                if (res.data) {
                    dispatch(userAction.setUser(res.data))
                    navigate('/')
                } else throw {msg: t('error.unknown')}
            })
            .catchApi(e => {
                setLoading(false)
                message.error(e.msg)
            })
    }, [email, password]);

    return (
        <Spin spinning={loading}>
            <StyledSignBackground style={{display: "flex", alignItems: "center", flexDirection: "column", paddingTop: 80, height: 'max(100vh, 100%)'}}>
                <img src={logoWithText}/>
                <div>
                    <div style={{height: 85}}/>
                    <StyledDiv font={'RoboBold32'} color={'White'}>{t('Welcome back')}</StyledDiv>
                    <div style={{height: 77}}/>
                    <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('Email')}</StyledDiv>
                    <Input style={{width: 586, height: 55}}
                           value={email}
                           onChange={event => setEmail(event.target.value)}/>
                    <div style={{height: 10}}/>
                    <StyledDiv font={'RoboBold16'} color={'white'} style={{marginBottom: 2}}>{t('Password')}</StyledDiv>
                    <Input.Password style={{width: 586, height: 55}}
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                    visibilityToggle={{visible: passwordVisible, onVisibleChange: setPasswordVisible}}/>
                </div>

                <div style={{height: 82}}/>
                <WhiteButton font={'RoboBold20'} text={t('Sign In')} style={{width: 586, height: 74}} onClick={handleClickSignIn}/>
                <StyledDiv font={'RoboBold20'} color={theme.colors.Color4A90E2} style={{marginTop: 24, cursor: 'pointer'}} onClick={() => {
                    window.location.replace('/password-find')
                }}>{t('Forgot your Password?')}</StyledDiv>
                <div style={{height: 80}}/>
            </StyledSignBackground>
        </Spin>
    );
}

export default SignInPage;
