import {ProblemType} from "./ProblemType";

export default class ReportRequest {
    constructor(
        public readonly reportedId: string,
        public readonly incidentType: ProblemType,
        public readonly comment: string,
    ) {
    }
}
