import React from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import platformImg from "../../assets/images/platform_pc.png"


function PlatformPage(props: {}) {
    return (
        <BasePageLayout style={{height: '100vh'}}>
            <div style={{height: 56}}/>
            <img src={platformImg} style={{width: '100%'}}/>
        </BasePageLayout>
    );
}

export default PlatformPage;
