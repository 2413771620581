import React, {useCallback, useEffect, useState} from 'react';
import {Col, List, message, Row} from "antd";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import ConvertUtil from "../../util/ConvertUtil";
import AnswerDetailView from "./AnswerDetailView";
import styled from "styled-components";
import ContentsApi from "../../api/manager/ContentsApi";
import ContentInfo from "../../model/question/ContentInfo";
import {useTranslation} from "react-i18next";
import EventBus from "../../eventbus/EventBus";
import {EventAnswerDeleted} from "../../eventbus/Event";

const ListItemContainer = styled.div`
  .ant-list-split {
    border-block-end: none;
  }
`

function AnswerListView(props: {
    questionId?: string
}) {

    const {t} = useTranslation()

    const [loading, setLoading] = useState(false);
    // const [indexOfPage, setIndexOfPage] = useState<number>(0)
    const [countInPage, setCountInPage] = useState<number>(10)  // default : 10
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState<ContentInfo[]>([]);

    useEffect(() => {
    }, [])

    useEffect(() => {

        if (props.questionId && props.questionId.length > 0) {
            requestAnswerList(props.questionId, 0)
        }

    }, [props.questionId])

    useEffect(() => {
        const subscription = EventBus.subscribe(EventAnswerDeleted, ev => {
            if (props.questionId) {
                requestAnswerList(props.questionId, 0)
            }
        })
        return () => subscription.unsubscribe()
    }, [props.questionId]);

    const requestAnswerList = useCallback((qId: string, indexOfPage: number) => {

        // progress
        setLoading(true)

        // request
        ContentsApi.getAnswers(qId, {
            withCount: true,
            indexOfPage: indexOfPage,
            countInPage: countInPage
        })
            .then(res => {
                setLoading(false)
                setTotalCount(res.totalSize ?? 0)
                setData(res.list ?? [])
            })
            .catchApi(e => {
                setLoading(false)
                message.error(e.msg)
            })
    }, [])

    return (
        <div>
            <Row>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboBold20'}>
                        {`${t('There is')} ${ConvertUtil.thousandSeparatorWithNumber(data.length)} ${t('answer to this question')}`}
                    </StyledSpan>
                </Col>
            </Row>
            <div style={{marginTop: 12}}>
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                        onChange: (page) => requestAnswerList(props.questionId ?? '', page - 1),
                        pageSize: countInPage,
                        total: totalCount
                    }}
                    dataSource={data}
                    renderItem={(item) => (
                        <ListItemContainer>
                            <List.Item
                                key={item._id}
                                style={{padding: 0, marginBottom: 25}}
                            >
                                <AnswerDetailView answerContentInfo={item}/>
                            </List.Item>
                        </ListItemContainer>
                    )}
                />
            </div>
        </div>
    );
}

export default AnswerListView;
