import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Row} from "antd";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import UriUtil from "../../util/UriUtil";
import SearchInputBoxView from "./view/SearchInputBoxView";
import Topic from "../../model/counsel/Topic";
import {getTransMapData} from "../../model/question/TranslateInfo";
import I18nUtil from "../../translations/I18nUtil";
import PreferenceApi from "../../api/manager/PreferenceApi";
import {useNavigate} from "react-router";

const TopicButtonContainer = styled.div`
  .ant-btn {
    background: #E8E8E8;
    border-radius: 100px;
    border-width: 0;
    font-style: normal;
    font-weight: 500;
  }

  .ant-btn-default {
    :hover {
      color: #777777;
      border-color: #777777;
    }
  }

  .ant-btn-primary {
    background-color: #000000;

    :hover {
      background-color: #777777;
    }
  }
`

function SearchPageHeaderView(props: { topic?: Topic }) {

    const {t} = useTranslation()
    const navigate = useNavigate()
    const [topicEducation, setTopicEducation] = useState<Topic>();
    const [topicCareer, setTopicCareer] = useState<Topic>();
    const [topicLiving, setTopicLiving] = useState<Topic>();

    useEffect(() => {
        PreferenceApi.getTopic('education').then(t => setTopicEducation(t))
        PreferenceApi.getTopic('career').then(t => setTopicCareer(t))
        PreferenceApi.getTopic('living').then(t => setTopicLiving(t))
    }, [])

    const handleClickTopic = useCallback((topicId?: string) => {
        const queryMap = UriUtil.parseQuery(window.location.href)
        if (props.topic?.id === topicId) {
            queryMap.set('topicId', '')
        } else {
            queryMap.set('topicId', topicId ?? '')
        }
        navigate(`/search?${UriUtil.queryMapToString(queryMap)}`)
    }, [props.topic]);

    const handleOnSearch = useCallback((searchText?: string) => {
        const queryMap = UriUtil.parseQuery(window.location.href)
        queryMap.set('keyword', searchText ?? '')
        navigate(`/search?${UriUtil.queryMapToString(queryMap)}`)
    }, []);

    return (
        <div>
            <Row>
                <StyledSpan color={'Black'}
                            font={'RoboBold36'}>
                    {props.topic?.id ? getTransMapData(props.topic.title, I18nUtil.getLanguage()) : t('Search')}
                </StyledSpan>
            </Row>
            <Row style={{marginTop: 24}}
                 align={"middle"}>
                <Col flex={"none"}>
                    <Row align={"middle"} gutter={[8, 8]}>
                        <Col>
                            <TopicButtonContainer>
                                <Button type={props.topic?.id === 'career' ? "primary" : "default"}
                                        onClick={event => handleClickTopic(topicCareer?.id)}>
                                    {getTransMapData(topicCareer?.title, I18nUtil.getLanguage())}
                                </Button>
                            </TopicButtonContainer>
                        </Col>
                        <Col>
                            <TopicButtonContainer>
                                <Button type={props.topic?.id === 'education' ? "primary" : "default"}
                                        onClick={event => handleClickTopic(topicEducation?.id)}>
                                    {getTransMapData(topicEducation?.title, I18nUtil.getLanguage())}
                                </Button>
                            </TopicButtonContainer>
                        </Col>
                        <Col>
                            <TopicButtonContainer>
                                <Button type={props.topic?.id === 'living' ? "primary" : "default"}
                                        onClick={event => handleClickTopic(topicLiving?.id)}>
                                    {getTransMapData(topicLiving?.title, I18nUtil.getLanguage())}
                                </Button>
                            </TopicButtonContainer>
                        </Col>
                    </Row>
                </Col>
                <Col flex={"auto"}/>
                <Col flex={"400px"}>
                    <SearchInputBoxView onSearch={handleOnSearch}/>
                </Col>
            </Row>
        </div>
    );
}

export default SearchPageHeaderView;
