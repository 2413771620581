import React, {useEffect, useState} from 'react';
import ContentInfo from "../../model/question/ContentInfo";
import {getTransMapData} from "../../model/question/TranslateInfo";
import I18nUtil from "../../translations/I18nUtil";
import EventBus from "../../eventbus/EventBus";
import {LanguageChangeEvent} from "../../eventbus/Event";

function HtmlContentDisplayView(props: {
    contentInfo?: ContentInfo
}) {

    const [htmlString, setHtmlString] = useState<string>('')
    const [forceRender, setForceRender] = useState<{}>();

    useEffect(() => {
        const subs = EventBus.subscribe(LanguageChangeEvent, ev => {
            setForceRender({})
        })
        return () => subs.unsubscribe()
    }, [])

    useEffect(() => {

        setHtmlString(prevState => {
            return getTransMapData(props.contentInfo?.descHtml, I18nUtil.getLanguage()) ?? ''
        })

    }, [props.contentInfo, forceRender])

    return (
        <>
            <div dangerouslySetInnerHTML={{__html: htmlString}}/>
        </>
    );
}

export default HtmlContentDisplayView;
