import React from 'react';
import styled from "styled-components";
import imgWhizBanner from "../../assets/images/img_whiz_banner_hello.svg"
import StyledSpan from "../base/styledcomponents/StyledSpan";
import ApiConf from "../../api/util/ApiConf";
import {useTranslation} from "react-i18next";

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 183px;
  background: radial-gradient(50% 1947.15% at 50% 55.74%, #1C3B7D 0.16%, #3058A9 100%)
`

function CreateWhizProfileBannerView(props: {}) {
    const {t} = useTranslation()

    return (
        <BannerContainer style={{cursor: "pointer"}} onClick={() => {
            window.open(ApiConf.allWhizMobileLink, '_blank')
        }}>
            <img width={126}
                 draggable={"false"}
                 style={{
                     position: "absolute",
                     left: 20,
                     bottom: 0,
                 }}
                 src={imgWhizBanner}/>
            <StyledSpan color={'White'}
                        font={'RoboRegular30'}
                        style={{
                            position: "absolute",
                            left: 194,
                            top: "50%",
                            msTransform: "translateY(-50%)",
                            transform: "translateY(-50%)"
                        }}>
                {t('Want to share your knowledge? Click here and become a Whiz!')}
            </StyledSpan>
        </BannerContainer>
    );
}

export default CreateWhizProfileBannerView;
