import React, {useEffect, useState} from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import {useParams} from "react-router-dom";
import QuestionDetailView from "../question/QuestionDetailView";
import AnswerListView from "../question/AnswerListView";
import CreateWhizProfileBannerView from "../main/CreateWhizProfileBannerView";
import {Button, Col, message, Row} from "antd";
import BackButtonView from "../question/BackButtonView";
import ContentsApi from "../../api/manager/ContentsApi";
import Lodash from "../../util/Lodash";
import ContentInfo from "../../model/question/ContentInfo";
import {WhizState} from "../../model/account/WhizState";
import {useAppSelector} from "../../reduxes/ReduxStore";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

function QuestionDetailPage(props: {}) {

    const {id} = useParams();
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [questionContent, setQuestionContent] = useState<ContentInfo | undefined>(undefined)
    const user = useAppSelector(state => state.userReducer.user)

    useEffect(() => {

        console.log(`[QUESTION] questionId:${id}`)

        // empty check
        if (Lodash.isUndefined(id) || id.length <= 0) {
            return
        }

        // get question detail
        ContentsApi.getContents(id).then(response => {

            // set data
            setQuestionContent(response.data)

        }).catchApi(e => {
            message.error(e.msg)
        })

    }, [id]);

    return (
        <BasePageLayout>
            <div style={{marginTop: 64}}>
                <BackButtonView/>
            </div>
            <div style={{marginTop: 32}}>
                <QuestionDetailView contentInfo={questionContent}/>
            </div>
            {user?.whizState === WhizState.confirmed ? <div style={{marginTop: 12}}>
                <Row>
                    <Col flex={"auto"}/>
                    <Col>
                        <Button type={"primary"}
                                style={{width: 176}}
                                size={"large"}
                                onClick={() => navigate(`/question/${id}/answer-write`)}>
                            {t('Answer')}
                        </Button>
                    </Col>
                </Row>
            </div> : undefined
            }
            <div style={{marginTop: 32}}>
                <AnswerListView questionId={id}/>
            </div>
            <div style={{marginTop: 24, marginBottom: 89}}>
                <CreateWhizProfileBannerView/>
            </div>
        </BasePageLayout>
    );
}

export default QuestionDetailPage;
