export default class BaseResponse<T> {
    success: boolean
    msg?: string
    code: number
    data?: T

    constructor(success: boolean, msg: string, code: number, data: T) {
        this.success = success;
        this.msg = msg;
        this.code = code;
        this.data = data;
    }
}