import StyledDiv from 'components/base/styledcomponents/StyledDiv';
import React, {useCallback, useEffect, useState} from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import {useTranslation} from "react-i18next";
import {Button, Input, message, Spin} from "antd";
import AccountApi from "../../api/manager/AccountApi";
import ResetPasswordRequest from "../../model/sign/ResetPasswordRequest";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {delay} from "../../util/AsyncUtil";

/**
 * 메일로 받은 password reset landing page
 */
function PasswordResetExecutePage(props: {}) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [code, setCode] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setCode(searchParams.get('code') ?? '')
    }, [searchParams])

    const handleClickSubmit = useCallback(() => {
        if (!password || !confirmPassword) {
            message.error(t('error.inputAll'))
            return
        }

        if (password.length < 8) {
            message.error(t('error.passwordFormat'))
            return
        }

        if (password !== confirmPassword) {
            message.error(t('error.newPasswordConfirmPasswordNotSame'))
            return
        }

        setLoading(true)
        AccountApi.resetPassword(new ResetPasswordRequest(code, password))
            .then(res => {
                setLoading(false)
                message.success(t('Password Reset Success'))
                return delay(1000)
            })
            .then(res => {
                navigate('/')
            })
            .catchApi(e => {
                setLoading(false)
                message.error(e.msg)
            })

    }, [password, confirmPassword, code, t]);

    return (
        <BasePageLayout>
            <Spin spinning={loading}>
                <div style={{height: 32}}/>
                <StyledDiv font={'RoboBold36'}>{t('Reset Password')}</StyledDiv>
                <div style={{height: 16}}/>
                <StyledDiv font={'RoboRegular20'}>{t('Please fill in follow blanks and click Submit to reset your password.')}</StyledDiv>
                <div style={{height: 32}}/>
                <StyledDiv font={'RoboRegular16'}>{t('New Password')}</StyledDiv>
                <div style={{height: 8}}/>
                <Input.Password placeholder={t('Minimum 8 characters')!} style={{width: 586, height: 55}}
                                visibilityToggle={{visible: passwordVisible, onVisibleChange: setPasswordVisible}}
                                value={password}
                                onChange={event => setPassword(event.target.value)}/>
                <div style={{height: 16}}/>
                <StyledDiv font={'RoboRegular16'}>{t('Confirm New Password')}</StyledDiv>
                <div style={{height: 8}}/>
                <Input.Password style={{width: 586, height: 55}}
                                visibilityToggle={{visible: passwordVisible, onVisibleChange: setPasswordVisible}}
                                value={confirmPassword}
                                onChange={event => setConfirmPassword(event.target.value)}/>
                <div style={{height: 16}}/>
                <Button onClick={handleClickSubmit}>{t('Submit')}</Button>
            </Spin>
        </BasePageLayout>
    );
}

export default PasswordResetExecutePage;
