import {configureStore} from "@reduxjs/toolkit";
import ApiConf from "../api/util/ApiConf";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {userReducer} from "./slices/userSlice";

// const persistConfig = {
//     key: 'aw_red_per',//local storage key
//     storage,//local storage
//     whitelist: []//reducer name
// }

// persistReducer(persistConfig, rootReducer)
const store = configureStore({
    devTools: ApiConf.isDevMode,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    reducer: {
        userReducer: userReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

//Custom Hooks 만들기
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch

// const persistor = persistStore(store)

export default {
    store
}
