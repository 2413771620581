import React, {useEffect, useState} from 'react';
import styled from "styled-components";

const BannerContainerOuter = styled.div`
  display: flex;
  justify-content: center;
`

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  aspect-ratio: 1448 / 631;
  background: #E8E8E8
`

function PartnerImageBannerView(props: {}) {

    const [imageBannerUrl, setImageBannerUrl] = useState<string>('')

    useEffect(() => {
        setImageBannerUrl('images/img_partner_1.png')
    }, [])

    return (
        <BannerContainerOuter>
            <BannerContainer>
                <img src={imageBannerUrl}
                     style={{
                         width: "100%",
                         height: "100%"
                     }}
                     alt={''}/>
            </BannerContainer>
        </BannerContainerOuter>
    );
}

export default PartnerImageBannerView;
