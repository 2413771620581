import React, {useCallback} from 'react';
import UserProfileImageView from "./view/UserProfileImageView";
import UserProfileInfoView from "./view/UserProfileInfoView";
import User from "../../model/account/User";
import StyledDiv from 'components/base/styledcomponents/StyledDiv';
import {useTranslation} from "react-i18next";
import {theme} from "../../styles/theme";
import CookieManager from "../../util/CookieManager";
import {useNavigate} from "react-router";
import {useAppDispatch} from "../../reduxes/ReduxStore";
import {userAction} from "../../reduxes/slices/userSlice";

function MyProfileView(props: {
    userInfo?: User
}) {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const handleLogout = useCallback(() => {
        dispatch(userAction.setUser(undefined))
        CookieManager.removeCookie(CookieManager.CookieKey.AccessToken)
        navigate('/',)
    }, []);

    return (
        <div>
            <UserProfileImageView userInfo={props.userInfo}/>
            <div style={{marginTop: 63}}>
                <UserProfileInfoView userInfo={props.userInfo}/>
            </div>

            <StyledDiv font={'RoboBold16'} color={theme.colors.ColorEB5757} style={{marginTop: 32, cursor: 'pointer'}} onClick={handleLogout}>{t('Logout')}</StyledDiv>
        </div>
    );
}

export default MyProfileView;
