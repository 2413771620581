import React from 'react';
import StyledDiv from './styledcomponents/StyledDiv';
import {FontKey} from "../../styles/theme";

function WhiteButton(props: { text: string, font?: FontKey, icon?: any | string } & React.HTMLAttributes<HTMLDivElement>) {

    return (
        <div style={{
            backgroundColor: "white",
            borderRadius: 10,
            display: "flex",
            columnGap: 36,
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            ...props.style
        }} onClick={props.onClick}>
            {props.icon && <img src={props.icon}/>}
            <StyledDiv font={props.font}>{props.text}</StyledDiv>
        </div>
    );
}

export default WhiteButton;
