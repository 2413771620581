import React from 'react';
import WysiwygEditor from "../../wysiwyg_editor/component/WysiwygEditor";

function HtmlContentEditView(props: {
    initialHtml: string,
    onChange: (html: string) => void
}) {

    return (
        <div style={{background: "gray"}}>
            <WysiwygEditor imageUploadUrlPath={'/files'} initialData={props.initialHtml} onChange={(event, editor) => props.onChange(editor.getData())}/>
        </div>
    );
}

export default HtmlContentEditView;
