import React from 'react';
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import HtmlContentEditView from "../../content/HtmlContentEditView";
import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";

function CreateQuestionContentView(props: {
    html: string, setHtml: React.Dispatch<string>
}) {
    const {t} = useTranslation()

    return (
        <>
            <StyledSpan color={'Black'}
                        font={'RoboMedium20'}>
                {t('Contents')}
            </StyledSpan>
            <div style={{marginTop: 8}}>
                <HtmlContentEditView initialHtml={props.html} onChange={props.setHtml}/>
            </div>
            <div style={{marginTop: 3}}>
                <Row>
                    <Col flex={"auto"}/>
                    <Col>
                        <StyledSpan color={'Black'}
                                    font={'RoboRegular14'}>
                            {t('It may take up to 1-2 business days to complete registration')}
                        </StyledSpan>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default CreateQuestionContentView;
