import React, {useEffect, useState} from 'react';
import {Col, Row, Skeleton} from "antd";
import TopicListItemView from "./view/TopicListItemView";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import {useTranslation} from "react-i18next";
import Topic from "../../model/counsel/Topic";
import I18nUtil from "../../translations/I18nUtil";
import {getTransMapData} from "../../model/question/TranslateInfo";

function MainTopicListView(props: {
    isLoaded?: boolean,
    topicInfoList?: Topic[]
}) {

    const {t} = useTranslation()

    const [careerTopic, setCareerTopic] = useState<Topic | undefined>(undefined)
    const [educationTopic, setEducationTopic] = useState<Topic | undefined>(undefined)
    const [livingTopic, setLivingTopic] = useState<Topic | undefined>(undefined)

    useEffect(() => {


        // #1. set topic data
        // id : career
        setCareerTopic(prevState => {
            return props.topicInfoList?.filter(value => value.id === 'career').shift()
        })

        // id : education
        setEducationTopic(prevState => {
            return props.topicInfoList?.filter(value => value.id === 'education').shift()
        })

        // id : living
        setLivingTopic(prevState => {
            return props.topicInfoList?.filter(value => value.id === 'living').shift()
        })

    }, [props.topicInfoList])

    return (
        <>
            {
                props.isLoaded ? (
                    <>
                        <Row>
                            <Col>
                                <StyledSpan color={'Black'}
                                            font={'RoboBold24'}>
                                    {t('Topics')}
                                </StyledSpan>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 12}} gutter={[16, 16]}>
                            <Col span={8}>
                                <TopicListItemView topicId={careerTopic?.id}
                                                   title={getTransMapData(careerTopic?.title, I18nUtil.getLanguage())}
                                                   imageUrl={careerTopic?.imageUrl}/>
                            </Col>
                            <Col span={8}>
                                <TopicListItemView topicId={educationTopic?.id}
                                                   title={getTransMapData(educationTopic?.title, I18nUtil.getLanguage())}
                                                   imageUrl={educationTopic?.imageUrl}/>
                            </Col>
                            <Col span={8}>
                                <TopicListItemView topicId={livingTopic?.id}
                                                   title={getTransMapData(livingTopic?.title, I18nUtil.getLanguage())}
                                                   imageUrl={livingTopic?.imageUrl}/>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Skeleton/>
                )
            }
        </>
    );
}

export default MainTopicListView;
