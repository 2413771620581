/**
 * Extension function 추가용
 * !!!!! TypeScript Extension 은 매우 드물게 동작 안되는 유저들이 있음! 가능하면 쓰지 말자.
 */
import ApiError from "./model/base/ApiError";
import {parseApiError} from "./model/base/ErrorAction";

declare global {
    interface Promise<T> {
        catchApi<TResult = never>(onrejected?: ((reason: ApiError) => TResult | PromiseLike<TResult>) | undefined | null): void
    }
}

//==========Promise
Promise.prototype.catchApi = function <TResult>(onrejected?: ((reason: ApiError) => TResult | PromiseLike<TResult>) | undefined | null) {
    return this.catch(error => {
        onrejected?.(parseApiError(error))
    })
}

export {}