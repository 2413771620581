import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import SignUpEmailView from "../sign/SignUpEmailView";
import SignUpPhoneNumberView from "../sign/SignUpPhoneNumberView";
import SignUpAuthCodeView from "../sign/SignUpAuthCodeView";
import UriUtil from "../../util/UriUtil";
import CryptoUtil from "../../util/CryptoUtil";
import {message, Spin} from "antd";
import RegexUtil from "../../util/RegexUtil";
import {AccountType, accountTypeToSnsProvider} from "../../model/account/AccountType";
import AccountApi from "../../api/manager/AccountApi";
import SmsVerificationRequest from "../../model/sign/SmsVerificationRequest";
import EmailSignUpRequest from "../../model/sign/EmailSignUpRequest";
import DeviceUtil from "../../util/DeviceUtil";
import SocialApi from "../../api/manager/SocialApi";
import SnsSignUpRequest from "../../model/sign/SnsSignUpRequest";
import CookieManager from "../../util/CookieManager";
import UserApi from "../../api/manager/UserApi";
import PhoneNumberUtil from "../../util/PhoneNumberUtil";
import {useNavigate} from "react-router";

type SignUpStep = 'email' | 'phoneNumber' | 'authCode'

function SignUpPage(props: {}) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [signUpStep, setSignUpStep] = useState<SignUpStep>('email');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneCountryCallingCode, setPhoneCountryCallingCode] = useState('+82');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [accountType, setAccountType] = useState<AccountType>();
    const [phoneAuthCode, setPhoneAuthCode] = useState('');
    const [snsToken, setSnsToken] = useState('');

    useEffect(() => {
        const uri = UriUtil.parseURL(window.location.href)
        const paramStr = CryptoUtil.decodeBase64(uri.queryMap.get('param')?.trim())
        if (paramStr) {
            const param = JSON.parse(paramStr)
            setAccountType(param.accountType)
            setSnsToken(param.snsToken ?? '')
            setEmail(param.email ?? '')
        }
    }, [])

    const checkEmailStep = useCallback(() => {
        if (!firstName || !lastName || !email) {
            message.error(t('error.inputAll'))
            return
        }
        if (!RegexUtil.validateEmail(email)) {
            message.error(t('error.emailFormat'))
            return
        }
        if ((!accountType || accountType === AccountType.email) && (!password || password.length < 8)) {
            message.error(t('error.passwordFormat'))
            return
        }
        setSignUpStep('phoneNumber')
    }, [firstName, lastName, email, password])

    const checkPhoneNumberStep = useCallback(() => {
        if (!phoneNumber) {
            message.error(t('error.inputAll'))
            return
        }

        setLoading(true)
        AccountApi.requestSmsVerification(new SmsVerificationRequest(PhoneNumberUtil.getNationalPhoneNumber(phoneCountryCallingCode, phoneNumber)))
            .then(res => {
                setLoading(false)
                setSignUpStep('authCode')
            }).catchApi(e => {
            message.error(e.msg)
            setLoading(false)
        })
    }, [phoneNumber])

    const checkPhoneAuthCodeStep = () => {
        if (!phoneAuthCode) {
            message.error(t('error.inputAll'))
            return
        }

        setLoading(true)
        const refinedPhoneNumber = PhoneNumberUtil.getNationalPhoneNumber(phoneCountryCallingCode, phoneNumber)
        const promise = accountType === AccountType.email ? AccountApi.emailSignUp(new EmailSignUpRequest(email, password, firstName, lastName, refinedPhoneNumber, phoneAuthCode, DeviceUtil.getDeviceUuid())) :
            SocialApi.snsSignUp(new SnsSignUpRequest(DeviceUtil.getDeviceUuid(), email, firstName, lastName, refinedPhoneNumber, phoneAuthCode, accountTypeToSnsProvider(accountType!)!, snsToken))

        promise.then(res => {
            if (res.data?.result) CookieManager.setCookie(CookieManager.CookieKey.AccessToken, res.data?.result)
            return UserApi.getMe()
        }).then(res => {
            setLoading(false)
            navigate('/')
        }).catchApi(e => {
            setLoading(false)
            message.error(e.msg)
        })
    }

    const emailView = useMemo(() => <SignUpEmailView
            accountType={accountType}
            firstName={firstName} setFirstName={setFirstName}
            lastName={lastName} setLastName={setLastName}
            email={email} setEmail={setEmail}
            password={password} setPassword={setPassword}
            onClickNext={checkEmailStep}/>,
        [accountType, firstName, lastName, email, password, checkEmailStep])

    const phoneNumberView = useMemo(() => <SignUpPhoneNumberView
        phoneCountryCallingCode={phoneCountryCallingCode} setPhoneCountryCallingCode={setPhoneCountryCallingCode}
        phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
        onClickNext={checkPhoneNumberStep}/>, [phoneNumber, checkPhoneNumberStep])

    const authCodeView = useMemo(() => <SignUpAuthCodeView
        phoneAuthCode={phoneAuthCode} setPhoneAuthCode={setPhoneAuthCode}
        onClickNext={checkPhoneAuthCodeStep}/>, [phoneAuthCode, checkPhoneAuthCodeStep])

    let markUp
    switch (signUpStep) {
        case "email":
            markUp = emailView
            break;
        case "phoneNumber":
            markUp = phoneNumberView
            break;
        case 'authCode':
            markUp = authCodeView
            break;
    }

    return (
        <Spin spinning={loading}>
            {markUp}
        </Spin>
    );
}

export default SignUpPage;
