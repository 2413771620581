import React, {useEffect, useState} from 'react';
import {List, message} from "antd";
import ContentInfo from "../../../model/question/ContentInfo";
import ContentsApi from "../../../api/manager/ContentsApi";
import UriUtil from "../../../util/UriUtil";
import QuestionContentListItemView from "./item/QuestionContentListItemView";
import {useSearchParams} from "react-router-dom";

function QuestionContentListView(props: { answered?: boolean }) {

    const [loading, setLoading] = useState(false);
    const [countInPage, setCountInPage] = useState<number>(10)  // default : 10
    const [totalCount, setTotalCount] = useState(0);
    const [contentInfoList, setContentInfoList] = useState<ContentInfo[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        requestContentInfoList(0)
    }, [searchParams])

    function requestContentInfoList(indexOfPage: number) {

        const uri = UriUtil.parseURL(window.location.href)

        // progress
        setLoading(true)

        // topic id
        const topicId = uri.queryMap.get('topicId')

        // keyword
        const keyword = uri.queryMap.get('keyword')

        // request
        ContentsApi.getQuestionList({
            topicId: topicId,
            isAnswered: props.answered,
            search: keyword,
            withCount: true,
            indexOfPage: indexOfPage,
            countInPage: countInPage
        }).then(res => {

            // progress
            setLoading(false)

            // total count
            setTotalCount(res.totalSize ?? 0)

            // set data
            setContentInfoList(res.list ?? [])
        }).catchApi(e => {

            // progress
            setLoading(false)
            message.error(e.msg)
        })
    }

    return (
        <div>
            <List
                loading={loading}
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => requestContentInfoList(page - 1),
                    pageSize: countInPage,
                    total: totalCount
                }}
                dataSource={contentInfoList}
                renderItem={(item) => (
                    <QuestionContentListItemView contentInfo={item}/>
                )}
            />
        </div>
    );
}

export default QuestionContentListView;
