import React, {useEffect, useState} from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import {useParams, useSearchParams} from "react-router-dom";
import QuestionDetailView from "../question/QuestionDetailView";
import CreateAnswerView from "../answer/CreateAnswerView";
import BackButtonView from "../question/BackButtonView";
import ContentInfo from "../../model/question/ContentInfo";
import Lodash from "../../util/Lodash";
import ContentsApi from "../../api/manager/ContentsApi";
import {message, Spin} from "antd";

function AnswerWritePage(props: {}) {

    const {id} = useParams();
    const [questionContent, setQuestionContent] = useState<ContentInfo | undefined>(undefined)
    const [searchParams, setSearchParams] = useSearchParams();
    const [editAnswer, setEditAnswer] = useState<ContentInfo>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const editAnswerId = searchParams.get('id')
        if (!editAnswerId) return
        setLoading(true)
        ContentsApi.getContents(editAnswerId)
            .then(res => {
                setLoading(false)
                setEditAnswer(res.data)
            })
            .catchApi(e => {
                setLoading(false)
            })
    }, [searchParams]);

    useEffect(() => {

        console.log(`[QUESTION] questionId:${id}`)

        // empty check
        if (Lodash.isUndefined(id) || id.length <= 0) {
            return
        }

        // get question detail
        ContentsApi.getContents(id).then(response => {
            // set data
            setQuestionContent(response.data)

        }).catchApi(e => {
            message.error(e.msg)
        })

    }, [id]);

    return (
        <BasePageLayout>
            <Spin spinning={loading}>
                <div style={{marginTop: 64}}>
                    <BackButtonView/>
                </div>
                <div style={{marginTop: 32}}>
                    <QuestionDetailView contentInfo={questionContent}/>
                </div>
                <div style={{marginTop: 24, marginBottom: 200}}>
                    <CreateAnswerView questionId={id!} editAnswer={editAnswer}/>
                </div>
            </Spin>
        </BasePageLayout>
    );
}

export default AnswerWritePage;
