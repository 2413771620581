import URLParse from "url-parse";
import Lodash from "./Lodash";
import qs from "qs";
import CryptoUtil from "./CryptoUtil";

// export type

export class ParsedURL {
    constructor(
        public readonly url: string,
        public readonly protocol: string,
        public readonly host: string,
        public readonly fullQuery: string,
        public readonly fullPath: string,
        public readonly pathList: string[],
        public readonly queryMap: Map<string, string>
    ) {

        function getFullUrl(): string {
            let refinedUrl = ''
            if (protocol) refinedUrl = protocol + '//'
            if (host) refinedUrl += host
            if (fullPath) refinedUrl += fullPath

            let queryObj = {}
            if (queryMap.size > 0) {
                Lodash.assign(queryObj, {...Object.fromEntries(queryMap)})
            }

            // @ts-ignore
            if (!Lodash.isEmpty(queryObj)) {
                refinedUrl += "?" + qs.stringify(queryObj)
            }
            return refinedUrl
        }
    }
}

function parseURL(url?: string): ParsedURL {
    if (!url) return {url: '', pathList: [], host: '', queryMap: new Map(), fullQuery: '', protocol: '', fullPath: ''}

    const urlParser = new URLParse(url)

    const pathList = urlParser.pathname.split('/').filter(value => value.length > 0)

    const queryMap = parseQuery(url)

    return new ParsedURL(
        url,
        urlParser.protocol,
        urlParser.host,
        urlParser.query,
        urlParser.pathname,
        pathList,
        queryMap,
    )
}

function parseQuery(url?: string): Map<string, string> {
    if (!url) return new Map<string, string>()
    const urlParser = new URLParse(url)

    const querySplit = urlParser.query.replace('?', '').split('&').map(query => {
        const keyValue = query.split('=')
        return {key: keyValue?.[0] ?? '', value: keyValue?.[1] ?? ''}
    })
    const queryMap = new Map<string, string>()
    for (const q of querySplit) {
        if (!!q.key) queryMap.set(CryptoUtil.decodeURL(q.key), CryptoUtil.decodeURL(q.value))
    }

    return queryMap
}

//return a=1&b=2
function queryMapToString(query?: Map<string, string>): string {
    if (!query || query.size === 0) return ''
    let result = ''
    query.forEach((value, key) => {
        if (key && value) {
            if (result) result += '&'
            result += `${key}=${value}`
        }
    })
    return result
}

export default {
    parseURL, parseQuery, queryMapToString
}
