import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, message, Row, Spin} from "antd";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import {CloseCircleOutlined, DeleteOutlined, EditOutlined, ShareAltOutlined} from "@ant-design/icons";
import ContentInfo from "../../../model/question/ContentInfo";
import ApiConf from "../../../api/util/ApiConf";
import {getTransMapData} from "../../../model/question/TranslateInfo";
import I18nUtil from "../../../translations/I18nUtil";
import DateUtil from "../../../util/DateUtil";
import {useTranslation} from "react-i18next";
import ReportModal from "../../user/ReportModal";
import ClipboardUtil from "../../../util/ClipboardUtil";
import EventBus from "../../../eventbus/EventBus";
import {LanguageChangeEvent} from "../../../eventbus/Event";
import whizProfileDefaultImg from "../../../assets/images/img_whiz_default.svg";
import {useAppSelector} from "../../../reduxes/ReduxStore";
import {useNavigate} from "react-router";
import ContentsApi from "../../../api/manager/ContentsApi";
import CryptoUtil from "../../../util/CryptoUtil";
import CookieManager from "../../../util/CookieManager";
import AccessTokenJwt from "../../../admin/model/AccessTokenJwt";
import AdminContentsApi from "../../../admin/api/manager/AdminContentsApi";

function QuestionDetailHeaderView(props: {
    contentInfo?: ContentInfo
}) {

    const {t} = useTranslation()

    const navigate = useNavigate()
    const userInfo = useAppSelector(state => state.userReducer.user)
    const [userProfileImageUrl, setUserProfileImageUrl] = useState<string>('');
    const [questionTitle, setQuestionTitle] = useState<string>('')
    const [userName, setUserName] = useState<string>('')
    const [createdAtText, setCreatedAtText] = useState<string>('')
    const [showReport, setShowReport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forceRender, setForceRender] = useState<{}>();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const jwt = CryptoUtil.parseJwt<AccessTokenJwt>(CookieManager.getCookie(CookieManager.CookieKey.AccessToken))
        if (jwt?.roles && jwt.roles.includes('ROLE_ADMIN')) setIsAdmin(true)

        const subs = EventBus.subscribe(LanguageChangeEvent, ev => {
            setForceRender({})
        })
        return subs.unsubscribe
    }, [])

    useEffect(() => {

        // #1. title
        setQuestionTitle(prevState => {
            return getTransMapData(props.contentInfo?.title, I18nUtil.getLanguage()) ?? ''
        })

        // #2. profile image
        if (props.contentInfo?.createdBy?.profileImageUuid) {
            setUserProfileImageUrl(prevState => {
                return `${ApiConf.fileUrlPrefix}/${props.contentInfo?.createdBy?.profileImageUuid}`
            })
        }

        // #3. user name
        setUserName(prevState => {
            return `${props.contentInfo?.createdBy?.lastName ?? ''} ${props.contentInfo?.createdBy?.firstName ?? ''} `
        })

        // #4. created at
        setCreatedAtText(prevState => {
            return DateUtil.format(props.contentInfo?.createdAt ?? 0, DateUtil.FORMAT_MM_DD_YYYY_hhmm_a)
        })

    }, [props.contentInfo, forceRender])

    const handleClickDelete = useCallback(() => {
        setLoading(true)
        const api = isAdmin ? AdminContentsApi.deleteContents(props.contentInfo?._id!) : ContentsApi.deleteContents(props.contentInfo?._id!)

        api.then(res => {
            setLoading(false)
            navigate(-1)
        }).catchApi(e => {
            setLoading(false)
            message.error(e.msg)
        })
    }, [props.contentInfo, isAdmin]);

    return (
        <>
            <Row>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboMedium24'}>
                        {questionTitle}
                    </StyledSpan>
                </Col>
            </Row>
            <Row style={{marginTop: 20}} align={"middle"} gutter={[12, 12]}>
                <Col>
                    <img style={{
                        width: 24,
                        height: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#F6F6F6',
                        borderRadius: 12,
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        objectFit: "contain"
                    }}
                         src={userProfileImageUrl ? userProfileImageUrl : whizProfileDefaultImg}/>
                </Col>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboRegular18'}>
                        {userName}
                    </StyledSpan>
                </Col>
                <Col>
                    <StyledSpan color={'Color828282'}
                                font={'RoboRegular16'}>
                        {createdAtText}
                    </StyledSpan>
                </Col>
                <Col flex={"auto"}/>
                <Col>
                    <Row align={"middle"}>
                        <Col>
                            <Button type="text"
                                    size={"small"}
                                    icon={<ShareAltOutlined/>}
                                    onClick={() => {
                                        ClipboardUtil.copy(window.location.href).then(res => message.success(t('Link Copied')))
                                    }}>
                                {t('Copy Link')}
                            </Button>
                        </Col>
                        <Col>
                            <Button type="text"
                                    size={"small"}
                                    icon={<CloseCircleOutlined/>}
                                    onClick={() => {
                                        setShowReport(true)
                                    }}>
                                {t('Report')}
                            </Button>
                        </Col>
                        {
                            isAdmin || props.contentInfo?.createdBy?._id === userInfo?._id ? <>
                                <Col>
                                    <Button type="text"
                                            size={"small"}
                                            icon={<EditOutlined/>}
                                            onClick={() => {
                                                if (isAdmin) navigate({pathname: `/admin/question-write/${props.contentInfo?._id}`})
                                                else navigate({pathname: `/question-write`, search: `?id=${props.contentInfo?._id}`})
                                            }}>
                                        {t('Edit')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Spin spinning={loading}>
                                        <Button type="text"
                                                size={"small"}
                                                icon={<DeleteOutlined/>}
                                                onClick={handleClickDelete}>
                                            {t('Delete')}
                                        </Button>
                                    </Spin>
                                </Col>
                            </> : undefined
                        }
                    </Row>
                </Col>
            </Row>
            <ReportModal open={showReport} callback={() => setShowReport(false)} targetId={props.contentInfo?._id}/>
        </>
    );
}

export default QuestionDetailHeaderView;
