import Lodash from "./Lodash";
import LocalStorageUtil from "./LocalStorageUtil";

const RECENT_SEARCH_KEY = '_re_se_ke_li'
const MAX_RECENT_COUNT = 10

function saveRecentSearchKeyword(searchKeyword?: string) {

    // skip
    if (Lodash.isUndefined(searchKeyword) || searchKeyword.length <= 0) {
        return
    }

    // keyword list
    const keywordList: string[] = [searchKeyword, ...loadRecentSearchKeyword().filter(value => value !== searchKeyword)]
    const end: number = keywordList.length <= MAX_RECENT_COUNT ? keywordList.length : MAX_RECENT_COUNT

    // save
    LocalStorageUtil.setItem(RECENT_SEARCH_KEY, JSON.stringify(keywordList.slice(0, end)))
}

function loadRecentSearchKeyword(): string[] {
    // load
    let res: string[] = []
    try {
        res = JSON.parse(LocalStorageUtil.getItem(RECENT_SEARCH_KEY)) as string []
    } catch (e) {
        // empty
    }
    return res
}

function removeRecentSearchKeyword(searchKeyword?: string) {

    // skip
    if (Lodash.isUndefined(searchKeyword) || searchKeyword.length <= 0) {
        return
    }

    const keywordList: string[] = loadRecentSearchKeyword().filter(value => value !== searchKeyword)

    // save
    LocalStorageUtil.setItem(RECENT_SEARCH_KEY, JSON.stringify([...keywordList]))
}

export default {
    saveRecentSearchKeyword,
    loadRecentSearchKeyword,
    removeRecentSearchKeyword,
}
