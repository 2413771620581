import {DefaultTheme} from "styled-components";

const colors = {
    Transparent: '#00000000',
    Default: '#0E0E0E',
    Black: '#000000',
    White: '#FFFFFF',
    Black50: '#00000080',
    Black3F3F3F: '#3F3F3F',
    Color828282: '#828282',
    Color15195A: '#15195A',
    Color4A90E2: '#4A90E2',
    Color2F80ED: '#2F80ED',
    ColorE8E8E8: '#E8E8E8',
    Color1A356C: '#1A356C',
    Color191F39: '#191F39',
    Color1F77DD: '#1F77DD',
    Color333333: '#333333',
    Color27AE60: '#27AE60',
    ColorEB5757: '#EB5757',
    Color898A8D: '#898A8D',
    ColorBDBDBD: '#BDBDBD',
    Color393939: '#393939',
}

const fonts = {
    /**
     * Thin: 300
     * Regular: 400
     * Medium: 500
     * Bold: 700
     */
    //Thin
    RoboThis15: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
    `,
    //Regular
    RoboRegular24: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
    `,
    RoboRegular22: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
    `,
    RoboRegular30: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
    `,
    RoboRegular20: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
    `,
    RoboRegular18: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
    `,
    RoboRegular16: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
    `,
    RoboRegular14: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
    `,
    RoboRegular12: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    `,
    //Medium
    RoboMedium18: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
    `,
    RoboMedium20: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
    `,
    RoboMedium24: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
    `,
    //Bold
    RoboBold36: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
    `,
    RoboBold32: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
    `,
    RoboBold24: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
    `,
    RoboBold20: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
    `,
    RoboBold18: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
    `,
    RoboBold16: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
    `,
    RoboBold14: `
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
    `,
}

export type ColorsType = typeof colors
export type FontsType = typeof fonts
export type FontKey = keyof FontsType
export type ColorKey = keyof ColorsType

export const theme: DefaultTheme = {
    colors: colors,
    fonts: fonts
}
