import React, {useEffect, useState} from 'react';
import {Col, Image, List, Row, Space} from "antd";
import {Link} from "react-router-dom";
import styled from "styled-components";
import StyledSpan from "../../../base/styledcomponents/StyledSpan";
import imgAnswerTag from "../../../../assets/images/img_answer_tag.svg"
import ContentInfo from "../../../../model/question/ContentInfo";
import {getTransMapData} from "../../../../model/question/TranslateInfo";
import I18nUtil from "../../../../translations/I18nUtil";
import ConvertUtil from "../../../../util/ConvertUtil";
import DateUtil from "../../../../util/DateUtil";
import EventBus from "../../../../eventbus/EventBus";
import {LanguageChangeEvent} from "../../../../eventbus/Event";
import {LikeOutlined} from "@ant-design/icons";

const ContentContainer = styled.div`
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  margin-bottom: 25px;
`

function QuestionContentListItemView(props: { contentInfo?: ContentInfo }) {

    const [answerCntText, setAnswerCntText] = useState<string>('')
    const [titleText, setTitleText] = useState<string>('')
    const [userName, setUserName] = useState<string>('')
    const [createdAtText, setCreatedAtText] = useState<string>('')
    const [forceRender, setForceRender] = useState<{}>();

    useEffect(() => {
        const subs = EventBus.subscribe(LanguageChangeEvent, ev => {
            setForceRender({})
        })
        return () => subs.unsubscribe()
    }, [])

    useEffect(() => {

        // #1. answer cnt
        setAnswerCntText(prevState => {
            return ConvertUtil.thousandSeparatorWithNumber(props.contentInfo?.cntAnswer) ?? '0'
        })

        // #2. title
        setTitleText(prevState => {
            return getTransMapData(props.contentInfo?.title, I18nUtil.getLanguage()) ?? ''
        })

        // #3. user name
        setUserName(prevState => {
            return ConvertUtil.getFullName(props.contentInfo?.createdBy?.firstName, props.contentInfo?.createdBy?.lastName)
        })

        // #4. created at
        setCreatedAtText(prevState => {
            return DateUtil.format(props.contentInfo?.createdAt ?? 0, DateUtil.FORMAT_MM_DD_YYYY)
        })

    }, [props.contentInfo, forceRender])

    const IconText = ({icon, text}: { icon: React.FC; text: string }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    return (
        <ContentContainer>
            <Link to={`/question/${props.contentInfo?._id}`}>
                <List.Item
                    key={props.contentInfo?._id}
                    actions={[
                        <div style={{marginLeft: 50}}>
                            <IconText icon={LikeOutlined}
                                      text={`${props.contentInfo?.cntLike ?? 0}`}
                                      key="list-vertical-like-o"/>
                        </div>,
                    ]}
                    extra={
                        <StyledSpan color={'Color828282'}
                                    font={'RoboRegular16'}>
                            {createdAtText}
                        </StyledSpan>
                    }
                >
                    <List.Item.Meta
                        avatar={<div>
                            <Row justify={"center"}>
                                <Col>
                                    <Image width={34}
                                           preview={false}
                                           src={imgAnswerTag}/>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 5}}
                                 justify={"center"}>
                                <Col>
                                    <StyledSpan color={'Color4A90E2'}
                                                font={'RoboRegular18'}>
                                        {answerCntText}
                                    </StyledSpan>
                                </Col>
                            </Row>
                        </div>}
                        title={
                            <StyledSpan color={'Black'}
                                        font={'RoboMedium24'}>
                                {titleText}
                            </StyledSpan>
                        }
                        description={
                            <StyledSpan color={'Black'}
                                        font={'RoboRegular18'}>
                                {userName}
                            </StyledSpan>
                        }
                    />
                </List.Item>
            </Link>
        </ContentContainer>
    );
}

export default QuestionContentListItemView;
