import React, {useCallback, useEffect, useState} from 'react';
import {Col, message, Row, Space, Spin} from "antd";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import {LikeFilled, LikeOutlined} from "@ant-design/icons";
import ContentInfo from "../../../model/question/ContentInfo";
import DateUtil from "../../../util/DateUtil";
import LikeApi from "../../../api/manager/LikeApi";

function AnswerDetailBottomView(props: {
    contentInfo?: ContentInfo
}) {

    const [createdAtText, setCreatedAtText] = useState<string>('')
    const [likeLoading, setLikeLoading] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [forceRender, setForceRender] = useState<{}>();

    useEffect(() => {
        if (!props.contentInfo) return

        // #1. created at
        setCreatedAtText(prevState => {
            return DateUtil.format(props.contentInfo?.createdAt ?? 0, DateUtil.FORMAT_MM_DD_YYYY_hhmm_a)
        })

        // #2. set is liked
        LikeApi.getAnswerLike(props.contentInfo._id)
            .then(res => {
                setIsLiked(!!res.data)
            }).catchApi(e => console.error(e))

    }, [props.contentInfo])

    const handleLike = useCallback(() => {
        if (!props.contentInfo) return
        setLikeLoading(true)

        const promise = isLiked ? LikeApi.deleteAnswerLike(props.contentInfo?._id) : LikeApi.postAnswerLike(props.contentInfo._id)
        promise.then(res => {
            if (props.contentInfo?.cntLike !== undefined) {
                if (isLiked) {
                    props.contentInfo.cntLike -= 1
                    setIsLiked(false)
                } else {
                    props.contentInfo.cntLike += 1
                    setIsLiked(true)
                }

                setForceRender({})
            }
            setLikeLoading(false)
        }).catchApi(e => {
            setLikeLoading(false)
            message.error(e.msg)
        })
    }, [props.contentInfo, isLiked]);

    const IconText = ({icon, text}: { icon: React.FC; text: string }) => (
        <Spin spinning={likeLoading}>
            <Space onClick={handleLike} style={{cursor: "pointer"}}>
                {React.createElement(icon)}
                {text}
            </Space>
        </Spin>
    );

    return (
        <>
            <Row style={{marginTop: 48}} align={"middle"}>
                <Col>
                    <StyledSpan color={'Color333333'}
                                font={'RoboRegular16'}>
                        {createdAtText}
                    </StyledSpan>
                </Col>
                <Col flex={"auto"}/>
                <Col>
                    <IconText icon={isLiked ? LikeFilled : LikeOutlined} text={`${props.contentInfo?.cntLike ?? 0}`} key="list-vertical-like-o"/>
                </Col>
            </Row>
        </>
    );
}

export default AnswerDetailBottomView;
