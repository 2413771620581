import React from 'react';
import CKEditorJS from "./CKEditorJS";
import '../styles/wysiwygeditor.css'

export type CKEvent = {
    name?: string
    eventInfo: {
        name?: string,
        path?: object[],
        source?: object
    }
}

export type CKEditor = {
    getData: () => string
}

function WysiwygEditor(props: {
    //ex) temp/images
    imageUploadUrlPath: string,
    initialData?: string,
    onReady?: (editor: any) => void,
    onChange?: (event: CKEvent, editor: CKEditor) => void,
    onBlur?: (event: CKEvent, editor: CKEditor) => void,
    onFocus?: (event: CKEvent, editor: CKEditor) => void,
}) {

    return (
        <div style={{bottom: 100}}>
            <CKEditorJS imageUploadUrlPath={props.imageUploadUrlPath}
                        data={props.initialData}
                        onReady={props.onReady}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}/>
        </div>
    );
}

export default WysiwygEditor;
