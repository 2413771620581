import {request} from "../../../api/util/ConnectUtil";
import {HttpMethod} from "../../../api/util/HttpMethod";
import ResultString from "../../../model/base/ResultString";
import BaseResponse from "../../../model/base/BaseResponse";
import AdminContentsRequest from "../../model/AdminPutQuestionRequest";

async function putContents(contentsId: string, req: AdminContentsRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.PUT, `/admin/contents/${contentsId}`, undefined, req)
}

async function deleteContents(contentsId: string): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.DELETE, `/admin/contents/${contentsId}`)
}

export default {
    putContents, deleteContents
}
