import ApiConf from "../../api/util/ApiConf";
import FileApi from "../../api/manager/FileApi";
import FileUtil from "../../util/FileUtil";

export default class WysiwygUploadAdapter {

    constructor(
        loader,
        urlPath,
    ) {
        this.loader = loader
        this.urlPath = urlPath
    }

    upload() {
        return new Promise(async (resolve, reject) => {
            this.loader.file.then((file) => {
                return FileUtil.fileToBlob(file)
            }).then(res => {
                return FileApi.uploadImage(this.urlPath, res)
            }).then((res) => {
                resolve({
                    default: ApiConf.fileUrlPrefix + '/' + res.data.data
                })
            }).catch((e) => {
                console.error(e)
                reject('Image upload failed')
            })
        })
    }

    abort() {
        //no implementation
    }
}
