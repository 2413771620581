import EmailSignUpRequest from "../../model/sign/EmailSignUpRequest";
import BaseResponse from "../../model/base/BaseResponse";
import ResultString from "../../model/base/ResultString";
import EmailSignInRequest from "../../model/sign/EmailSignInRequest";
import LogoutRequest from "../../model/sign/LogoutRequest";
import SmsVerificationRequest from "../../model/sign/SmsVerificationRequest";
import PasswordResetRequest from "../../model/sign/PasswordResetRequest";
import ChangePasswordRequest from "../../model/sign/ChangePasswordRequest";
import FindEmailRequest from "../../model/sign/FindEmailRequest";
import {request} from "../util/ConnectUtil";
import {HttpMethod} from "../util/HttpMethod";
import ResetPasswordRequest from "../../model/sign/ResetPasswordRequest";

async function emailSignUp(req: EmailSignUpRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, '/account/signup', undefined, req)
}

/** 이메일 로그인 */
async function emailSignIn(req: EmailSignInRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, '/account/signin', undefined, req)
}

/** 로그아웃 */
async function logout(req: LogoutRequest): Promise<BaseResponse<undefined>> {
    return await request(HttpMethod.POST, '/account/logout', undefined, req)
}

/** 휴대폰 인증요청 */
async function requestSmsVerification(req: SmsVerificationRequest): Promise<BaseResponse<undefined>> {
    return await request(HttpMethod.POST, '/account/request-sms-verification', undefined, req)
}

/** 패스워드 리셋 요청 */
async function requestPasswordReset(req: PasswordResetRequest): Promise<BaseResponse<undefined>> {
    return await request(HttpMethod.POST, '/account/request-reset-pw-email', undefined, req)
}

/** 패스워드 리셋 */
async function resetPassword(req: ResetPasswordRequest): Promise<BaseResponse<undefined>> {
    return await request(HttpMethod.POST, '/account/reset-pw', undefined, req)
}

/** 패스워드 변경 */
async function changePassword(req: ChangePasswordRequest): Promise<BaseResponse<undefined>> {
    return await request(HttpMethod.POST, '/account/change-pw', undefined, req)
}

/** 이메일 찾기 */
async function findEmail(req: FindEmailRequest): Promise<BaseResponse<ResultString>> {
    return await request(HttpMethod.POST, '/account/find-my-email', undefined, req)
}

export default {
    emailSignUp, emailSignIn, logout, requestSmsVerification, requestPasswordReset, changePassword, findEmail, resetPassword
}
