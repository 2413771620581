import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import ApiConf from "../../api/util/ApiConf";

function RedirectGooglePlayPage(props: {}) {

    const {id} = useParams();

    useEffect(() => {
        console.log(`[REDIRECT] id:${id}`)
        window.location.href = ApiConf.allWhizGooglePlayLink
    }, [id]);

    return (
        <>
        </>
    );
}

export default RedirectGooglePlayPage;
