import React, {useEffect, useMemo, useState} from 'react';
import {Button, Tabs} from "antd";
import QuestionContentListView from "../question/list/QuestionContentListView";
import {WhizState} from "../../model/account/WhizState";
import {useTranslation} from "react-i18next";
import EventBus from "../../eventbus/EventBus";
import {LanguageChangeEvent} from "../../eventbus/Event";
import {useAppSelector} from "../../reduxes/ReduxStore";
import {useNavigate} from "react-router";

function SearchQuestionTabView(props: {}) {

    const {t} = useTranslation()
    const navigate = useNavigate()
    const user = useAppSelector(state => state.userReducer.user)
    const [forceRender, setForceRender] = useState<{}>();

    useEffect(() => {
        const subs = EventBus.subscribe(LanguageChangeEvent, ev => {
            setForceRender({})
        })
        return () => subs.unsubscribe()
    }, [])

    const onChange = (key: string) => {
        console.log(key);
    };

    const operations = <Button onClick={() => navigate('/question-write')}>
        {t('Ask Question')}
    </Button>;


    const tabItems = useMemo(() => {
        const result = [{
            label: t(`Answered`),
            key: '1',
            children: <QuestionContentListView answered={true}/>,
        }]

        if (user?.whizState === WhizState.confirmed) {
            result.push(
                {
                    label: t(`Unanswered`),
                    key: '2',
                    children: <QuestionContentListView answered={false}/>,
                }
            )
        }

        return result
    }, [user, forceRender])

    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                onChange={onChange}
                tabBarExtraContent={operations}
                items={tabItems}
            />
        </div>
    );
}

export default SearchQuestionTabView;
