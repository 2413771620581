import React, {useEffect, useMemo} from 'react';
import {Route, Routes} from "react-router-dom";
import CookieManager from "./util/CookieManager";
import MainPage from "./components/pages/MainPage";
import SignStartPage from "./components/pages/SignStartPage";
import SignInPage from "./components/pages/SignInPage";
import PasswordFindPage from "./components/pages/PasswordFindPage";
import SearchPage from "./components/pages/SearchPage";
import QuestionDetailPage from "./components/pages/QuestionDetailPage";
import QuestionWritePage from "./components/pages/QuestionWritePage";
import MyPage from "./components/pages/MyPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import SignUpPage from "./components/pages/SignUpPage";
import AnswerWritePage from "./components/pages/AnswerWritePage";
import ContactUsPage from "./components/pages/ContactUsPage";
import AboutPage from "./components/pages/AboutPage";
import PlatformPage from "./components/pages/PlatformPage";
import PolicyPage from "./components/pages/PolicyPage";
import PasswordResetExecutePage from "./components/pages/PasswordResetExecutePage";
import {useAppDispatch, useAppSelector} from "./reduxes/ReduxStore";
import UserApi from "./api/manager/UserApi";
import {userAction} from "./reduxes/slices/userSlice";
import AdminQuestionDetailPage from "./admin/components/AdminQuestionDetailPage";
import AdminAnswerDetailPage from "./admin/components/AdminAnswerDetailPage";
import RedirectGooglePlayPage from "./components/pages/RedirectGooglePlayPage";

function App() {

    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.userReducer.user)
    const accessToken = CookieManager.getCookie(CookieManager.CookieKey.AccessToken)

    useEffect(() => {
        if (user || !accessToken) return

        UserApi.getMe().then(res => {
            dispatch(userAction.setUser(res.data))
        })
    }, [user, accessToken])

    const mainPage = useMemo(() => (<MainPage/>), [])
    const signStartPage = useMemo(() => (<SignStartPage/>), [])
    const signUpPage = useMemo(() => (<SignUpPage/>), [])
    const signInPage = useMemo(() => (<SignInPage/>), [])
    const passwordFindPage = useMemo(() => (<PasswordFindPage/>), [])
    const searchPage = useMemo(() => (<SearchPage/>), [])
    const questionDetailPage = useMemo(() => (<QuestionDetailPage/>), [])
    const answerWritePage = useMemo(() => (<AnswerWritePage/>), [])
    const questionWritePage = useMemo(() => (<QuestionWritePage/>), [])
    const myPage = useMemo(() => (<MyPage/>), [])
    const notFoundPage = useMemo(() => (<NotFoundPage/>), [])
    const contactPage = useMemo(() => (<ContactUsPage/>), [])
    const aboutPage = useMemo(() => (<AboutPage/>), [])
    const platformPage = useMemo(() => (<PlatformPage/>), [])
    const policyPage = useMemo(() => (<PolicyPage/>), [])
    const passwordResetExecutePage = useMemo(() => (<PasswordResetExecutePage/>), [])
    const redirectGooglePlayPage = useMemo(() => (<RedirectGooglePlayPage/>), [])

    //Admin
    const adminQuestionWritePage = useMemo(() => <AdminQuestionDetailPage/>, [])
    const adminAnswerWritePage = useMemo(() => <AdminAnswerDetailPage/>, [])

    return (
        <>
            <div>
                <Routes>
                    <Route path="/" element={mainPage}/>
                    <Route path={"/admin/question-write/:id"} element={adminQuestionWritePage}/>
                    <Route path={"/admin/answer-write/:id"} element={adminAnswerWritePage}/>
                    <Route path="/sign-start" element={signStartPage}/>
                    <Route path="/sign-up" element={signUpPage}/>
                    <Route path="/sign-in" element={signInPage}/>
                    <Route path="/password-find" element={passwordFindPage}/>
                    <Route path={"/search"} element={searchPage}/>
                    <Route path={"/question/:id"} element={questionDetailPage}/>
                    <Route path={"/question/:id/answer-write"} element={answerWritePage}/>
                    <Route path={"/question-write"} element={questionWritePage}/>
                    <Route path={"/mypage"} element={myPage}/>
                    <Route path={"/contact"} element={contactPage}/>
                    <Route path={"/about"} element={aboutPage}/>
                    <Route path={"/platform"} element={platformPage}/>
                    <Route path={"/policy"} element={policyPage}/>
                    <Route path={'/privacypolicy'} element={policyPage}/>
                    <Route path={"/reset-pw"} element={passwordResetExecutePage}/>
                    <Route path={'/whiz/:id'} element={redirectGooglePlayPage}/>
                    <Route path="/*" element={notFoundPage}/>
                </Routes>
            </div>
        </>

    );
}

export default App;
