import React, {useEffect, useState} from 'react';
import styled from "styled-components";

const BannerContainerOuter = styled.div`
  display: flex;
  justify-content: center;
`

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  aspect-ratio: 1920 / 1080;
  background: #E8E8E8
`

function ContentVideoBannerView(props: {
    youtubeVideoId?: string
}) {

    const [videoUrl, setVideoUrl] = useState<string>('')

    useEffect(() => {

        setVideoUrl(prevState => {
            if (props.youtubeVideoId && props.youtubeVideoId.length > 0) {
                return `https://www.youtube.com/embed/${props.youtubeVideoId}?autoplay=1&mute=1&enablejsapi=1&loop=1&playlist=${props.youtubeVideoId}`
            } else {
                return ''
            }
        })

    }, [props.youtubeVideoId])

    return (
        <BannerContainerOuter>
            <BannerContainer className={'video'}>
                <iframe
                    title="Embeded Video"
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                    src={videoUrl}
                    frameBorder="0"
                />
            </BannerContainer>
        </BannerContainerOuter>
    );
}

export default ContentVideoBannerView;
