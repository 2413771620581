import {download, downloadFromS3, downloadPublicFile, upload, uploadUrl} from "../util/ConnectUtil";
import ApiConf from "../util/ApiConf";
import fileUtil from "../../util/FileUtil";
import BaseResponse from "../../model/base/BaseResponse";

async function uploadImage(path: string | undefined, fileBlobData?: Blob | string | null) {

    // POST /files
    return await upload({
        'isPublic': true,
        'noChangeFilename': false,
        'isCreateThumbnail': false,
        'filePath': (path ? path : null), // `products/${productId}` // products/${product.id}
    }, fileBlobData);
}

async function uploadByUrl(publicImageUrl: string, filePath: string, isResize: boolean) {
    return await uploadUrl<BaseResponse<string>>({
        url: publicImageUrl,
        isPublic: true,
        noChangeFilename: false,
        isCreateThumbnail: false,
        filePath: filePath,
        isResize: isResize,
        isImage: true
    })
}

async function uploadHtml(fileName?: string, fileBlobData?: Blob | string) {

    // POST /files
    return await upload({
        'isPublic': true,
        'noChangeFilename': !!fileName,
        'isCreateThumbnail': false,
        'filePath': 'htmls',
    }, fileBlobData, fileName ?? 'temp.html');

}

async function downloadFile(path?: string) {

    console.log('download file path : ' + path);

    // GET /download
    return await download(path, undefined);
}

async function downloadFileFromS3(path?: string) {
    return await downloadFromS3(path ?? '');
}

async function downloadImageFile(path?: string) {
    console.log('download file path : ' + path);

    // GET /download
    return await download(path, "blob");
}

async function downloadPublicImage(url: string): Promise<Blob> {
    const fileRes = await downloadPublicFile(url, 'blob')
    let mimeType = await fileUtil.getMimeType(fileRes.data)
    if (!mimeType) mimeType = fileUtil.getFileExtensionFromUrl(url)
    if (!mimeType) mimeType = 'image/jpeg'

    //type 을 이미지로 명시해주지 않으면, 스마트스토어에서 거부당함
    return new Blob([fileRes.data], {type: mimeType})
}

function imageFileUpload(path: string, imageFile?: File, serverImageUrl?: string | null): Promise<string> {
    return new Promise((resolve, reject) => {

        // #1. there is no file for upload. skip
        if (imageFile === undefined) {
            console.log('image file is empty, skip!');
            return resolve(serverImageUrl ?? '');
        }

        // #2. file read
        fileUtil.fileReader(imageFile).then(dataURL => {

            // file read success!
            console.log('file read success!');
            let blob = fileUtil.dataURLtoBlob(dataURL);

            // file upload
            console.log('start file upload');
            uploadImage(path, blob).then(response => {

                // log
                console.log('file upload success!!');
                console.log(response.data);

                // return full image url
                return resolve(ApiConf.fileUrlPrefix + '/' + response.data?.data);

            }).catch(reason => {

                // log
                console.log('file upload fail. skip, error : ' + reason.response);

                return resolve(serverImageUrl ?? "");
            });

        }).catch(error => {
            console.log('file read fail, skip!, error : ' + error);
            return resolve(serverImageUrl ?? '');
        });

    });
}

function imageDataUrlUpload(path: string, dataUrl?: string, serverImageUrl?: string | null): Promise<string> {
    return new Promise((resolve, reject) => {

        // #1. there is no file for upload. skip
        if (dataUrl === undefined) {
            console.log('image data uri is empty, skip!');
            return resolve(serverImageUrl ?? '');
        }

        let blob = fileUtil.dataURLtoBlob(dataUrl);

        // file upload
        console.log('start file upload');
        uploadImage(path, blob).then(response => {

            // log
            console.log('file upload success!!');
            console.log(response.data);

            // return full image url
            return resolve(ApiConf.fileUrlPrefix + '/' + response.data?.data);

        }).catch(reason => {

            // log
            console.log('file upload fail. skip, error : ' + reason.response);

            return resolve(serverImageUrl ?? "");
        });
    });
}

export default {
    uploadImage, uploadByUrl, uploadHtml, downloadFile, downloadFileFromS3, downloadImageFile,
    imageFileUpload, imageDataUrlUpload, downloadPublicImage
}
