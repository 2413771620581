import styled from "styled-components";
import {ColorKey, FontKey} from "../../../styles/theme";

export interface TextProps {
    color?: ColorKey | string
    font?: FontKey
}

export default styled.div<TextProps>`
  color: ${props => {
    if (props.color && props.color in props.theme.colors) {
      // @ts-ignore
      return props.theme.colors[props.color]
    } else return props.color
  }};
  ${props => props.font ? props.theme.fonts[props.font] : undefined};
`
