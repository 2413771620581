import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, message, Row, Spin} from "antd";
import StyledSpan from "../../base/styledcomponents/StyledSpan";
import {CloseCircleOutlined, DeleteOutlined, EditOutlined, ShareAltOutlined} from "@ant-design/icons";
import ContentInfo from "../../../model/question/ContentInfo";
import ApiConf from "../../../api/util/ApiConf";
import {useTranslation} from "react-i18next";
import ReportModal from "../../user/ReportModal";
import ClipboardUtil from "../../../util/ClipboardUtil";
import whizProfileDefaultImg from "../../../assets/images/img_whiz_default.svg";
import {useAppSelector} from "../../../reduxes/ReduxStore";
import ContentsApi from "../../../api/manager/ContentsApi";
import EventBus from "../../../eventbus/EventBus";
import {EventAnswerDeleted} from "../../../eventbus/Event";
import {useNavigate} from "react-router";
import CryptoUtil from "../../../util/CryptoUtil";
import AccessTokenJwt from "../../../admin/model/AccessTokenJwt";
import CookieManager from "../../../util/CookieManager";
import AdminContentsApi from "../../../admin/api/manager/AdminContentsApi";

function AnswerDetailHeaderView(props: {
    hideAction?: boolean,
    contentInfo?: ContentInfo
}) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const userInfo = useAppSelector(state => state.userReducer.user)
    const [userProfileImageUrl, setUserProfileImageUrl] = useState<string>('');
    const [residenceText, setResidenceText] = useState<string>('')
    const [userName, setUserName] = useState<string>('')
    const [firstExperience, setFirstExperience] = useState<string>('')
    const [whizProfileLink, setWhizProfileLink] = useState<string>('')
    const [showReport, setShowReport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const jwt = CryptoUtil.parseJwt<AccessTokenJwt>(CookieManager.getCookie(CookieManager.CookieKey.AccessToken))
        if (jwt?.roles && jwt.roles.includes('ROLE_ADMIN')) setIsAdmin(true)

        // #1. profile image
        if (props.contentInfo?.createdBy?.profileImageUuid) {
            setUserProfileImageUrl(prevState => {
                return `${ApiConf.fileUrlPrefix}/${props.contentInfo?.createdBy?.profileImageUuid}`
            })
        }

        // #2. residence text
        setResidenceText(prevState => {
            return props.contentInfo?.createdBy?.residence ?? ''
        })

        // #3. user name
        setUserName(prevState => {
            return `${props.contentInfo?.createdBy?.lastName ?? ''} ${props.contentInfo?.createdBy?.firstName ?? ''} `
        })

        // #4. first experience
        setFirstExperience(prevState => {
            return props.contentInfo?.createdBy?.experiences?.shift()?.desc ?? ''
        })

        // #5. whiz profile
        setWhizProfileLink(prevState => {
            return props.contentInfo?.createdBy?.link ?? ''
        })

    }, [props.contentInfo])

    const handleClickDelete = useCallback(() => {
        setLoading(true)

        const api = isAdmin ? AdminContentsApi.deleteContents(props.contentInfo?._id!) : ContentsApi.deleteContents(props.contentInfo?._id!)
        api.then(res => {
            setLoading(false)
            EventBus.sendEvent(new EventAnswerDeleted(props.contentInfo?._id!))
        }).catchApi(e => {
            setLoading(false)
            message.error(e.msg)
        })
    }, [props.contentInfo]);

    return (
        <>
            <Row align={"middle"} gutter={[12, 12]}>
                <Col>
                    <img style={{
                        width: 24,
                        height: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#F6F6F6',
                        borderRadius: 12,
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        objectFit: "contain"
                    }}
                         src={userProfileImageUrl ? userProfileImageUrl : whizProfileDefaultImg}/>
                </Col>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboMedium24'}>
                        {userName}
                    </StyledSpan>
                </Col>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboRegular16'}>
                        {residenceText}
                    </StyledSpan>
                </Col>
            </Row>
            <Row style={{marginTop: 13}} align={"middle"} gutter={[0, 12]}>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboRegular16'}>
                        {firstExperience}
                    </StyledSpan>
                </Col>
                <Col>
                    <Button type="link" onClick={() => {
                        if (whizProfileLink.length > 0) {
                            window.open(whizProfileLink, '_blank')
                        }
                    }}>{t('Whiz Profile')}</Button>
                </Col>
                <Col flex={"auto"}/>
                {
                    !props.hideAction ? (
                        <Col>
                            <Row align={"middle"}>
                                <Col>
                                    <Button type="text" size={"small"}
                                            icon={<ShareAltOutlined/>}
                                            onClick={event => ClipboardUtil.copy(window.location.href).then(res => message.success(t('Link Copied')))}>{t('Copy Link')}</Button>
                                </Col>
                                <Col>
                                    <Button type="text" size={"small"}
                                            icon={<CloseCircleOutlined/>}
                                            onClick={event => setShowReport(true)}>{t('Report')}</Button>
                                </Col>
                            </Row>
                        </Col>
                    ) : undefined
                }
                {
                    isAdmin || props.contentInfo?.createdBy?._id === userInfo?._id ? <>
                        <Col>
                            <Button type="text"
                                    size={"small"}
                                    icon={<EditOutlined/>}
                                    onClick={() => {
                                        if (isAdmin) navigate({
                                            pathname: `/admin/answer-write/${props.contentInfo?._id}`,
                                        })
                                        else navigate({
                                            pathname: `/question/${props.contentInfo?.question?._id}/answer-write`,
                                            search: `?id=${props.contentInfo?._id}`
                                        })
                                    }}>
                                {t('Edit')}
                            </Button>
                        </Col>
                        <Col>
                            <Spin spinning={loading}>
                                <Button type="text"
                                        size={"small"}
                                        icon={<DeleteOutlined/>}
                                        onClick={handleClickDelete}>
                                    {t('Delete')}
                                </Button>
                            </Spin>
                        </Col>
                    </> : undefined
                }
            </Row>
            <ReportModal open={showReport} callback={() => setShowReport(false)} targetId={props.contentInfo?._id}/>
        </>
    );
}

export default AnswerDetailHeaderView;
