import React from 'react';
import BasePageLayout from "../base/layout/BasePageLayout";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import I18nUtil from "../../translations/I18nUtil";
import {LocateType} from "../../model/question/LocateType";

function PolicyPage(props: {}) {
    const {t} = useTranslation()

    function getTermsOfServiceUrl() {
        if (I18nUtil.getLanguage() === LocateType.ko) return 'https://file.allwhiz.com/public/html/term_of_service_ko.html'
        else return 'https://file.allwhiz.com/public/html/term_of_service.html'
    }

    function getPrivacyPolicyUrl() {
        if (I18nUtil.getLanguage() === LocateType.ko) return 'https://file.allwhiz.com/public/html/privacy_policy_ko.html'
        else return 'https://file.allwhiz.com/public/html/privacy_policy.html'
    }

    function getCookiePolicyUrl() {
        if (I18nUtil.getLanguage() === LocateType.ko) return 'https://file.allwhiz.com/public/html/cookie_policy_ko.html'
        else return 'https://file.allwhiz.com/public/html/cookie_policy.html'
    }

    return (
        <BasePageLayout style={{height: '100vh'}}>
            <Tabs
                items={[
                    {
                        key: 'terms',
                        label: t('Terms of Service'),
                        children: (<>
                            <iframe src={getTermsOfServiceUrl()} style={{width: '100%', height: '70vh'}}/>
                        </>)
                    },
                    {
                        key: 'privacy',
                        label: t('Privacy Policy'),
                        children: (<div>
                            <iframe src={getPrivacyPolicyUrl()} style={{width: '100%', height: '70vh'}}/>
                        </div>)
                    },
                    {
                        key: 'cookie',
                        label: t('Cookie Policy'),
                        children: (<div>
                            <iframe src={getCookiePolicyUrl()} style={{width: '100%', height: '70vh'}}/>
                        </div>)
                    },
                ]}
            />
        </BasePageLayout>
    );
}

export default PolicyPage;
