import React from 'react';
import {Divider} from "antd";
import styled from "styled-components";
import HtmlContentDisplayView from "../content/HtmlContentDisplayView";
import AnswerDetailHeaderView from "./view/AnswerDetailHeaderView";
import AnswerDetailBottomView from "./view/AnswerDetailBottomView";
import ContentInfo from "../../model/question/ContentInfo";

const ContentContainer = styled.div`
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 24px 50px 24px 50px;
`

function AnswerDetailView(props: {
    answerContentInfo?: ContentInfo
}) {

    return (
        <ContentContainer>
            <AnswerDetailHeaderView contentInfo={props.answerContentInfo}/>
            <Divider style={{marginTop: 12, marginBottom: 32}}/>
            <HtmlContentDisplayView contentInfo={props.answerContentInfo}/>
            <div style={{marginTop: 48}}>
                <AnswerDetailBottomView contentInfo={props.answerContentInfo}/>
            </div>
        </ContentContainer>
    );
}

export default AnswerDetailView;
