import {SnsProvider} from "../sign/SnsProvider";

export enum AccountType {
    email = 'email',
    facebook = 'facebook',
    apple = 'apple',
    google = 'google'
}

export function accountTypeToSnsProvider(type: AccountType): SnsProvider | undefined {
    switch (type) {
        case AccountType.apple:
            return SnsProvider.apple
        case AccountType.facebook:
            return SnsProvider.facebook
        case AccountType.google:
            return SnsProvider.google
        case AccountType.email:
            return undefined
    }
}
