import React, {useCallback} from 'react';
import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import {useNavigate} from "react-router";
import SearchInputBoxView from "../search/view/SearchInputBoxView";

function MainSearchQuestionsView(props: {}) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const handleOnSearch = useCallback((searchText?: string) => {

        // #1. route to search result page
        navigate(`/search?keyword=${searchText ?? ''}`)

    }, []);

    return (
        <>
            <Row justify={"center"}>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboBold24'}>
                        {t('Ask us anything!')}
                    </StyledSpan>
                </Col>
            </Row>
            <Row style={{marginTop: 12}} justify={"center"}>
                <Col span={12}>
                    <SearchInputBoxView onSearch={handleOnSearch}/>
                </Col>
            </Row>
        </>
    );
}

export default MainSearchQuestionsView;
