import moment from "moment";

const FORMAT_yyyy_MM_DD_HHmm = 'yyyy/MM/DD HH:mm'
const FORMAT_yyyy_MM_DD = 'yyyy/MM/DD'
const FORMAT_YY_MM_DD = 'YY/MM/DD'
const FORMAT_MM_DD_YYYY_hhmm_a = 'MM/DD/yyyy hh:mm a'
const FORMAT_MM_DD_YYYY = 'MM/DD/yyyy'
const MM_DD = 'MM.DD'

const SECOND = 1000
const MINUTE = 60000
const HOUR = 3600000
const DAY = 86400000
const WEEK = 604800000
const YEAR = 31536000000

function format(timestamp: number, formatString: string) {
    return moment(timestamp).format(formatString)
}

//2022-07-22T16:47:00+09:00
function formatISO8601(timestamp: number): string {
    return moment(timestamp).toISOString(true)
}

//두 날짜 차이 (소수점 내림 처리함)
function difference(fromTimestamp: number, toTimestamp: number, unit: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'): number {
    const from = moment(fromTimestamp)
    const to = moment(toTimestamp)
    return to.diff(from, unit)
}

export default {
    SECOND, MINUTE, HOUR, DAY, WEEK, YEAR,
    FORMAT_yyyy_MM_DD_HHmm, FORMAT_yyyy_MM_DD, FORMAT_YY_MM_DD, MM_DD, FORMAT_MM_DD_YYYY_hhmm_a, FORMAT_MM_DD_YYYY,
    format, formatISO8601, difference
}
