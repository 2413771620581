import React from 'react';
import {Col, Image, Row} from "antd";
import StyledSpan from "../base/styledcomponents/StyledSpan";
import leftArrowIcon from "../../assets/images/ic_left_arrow.svg";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

function BackButtonView(props: {
    title?: string
}) {
    const {t} = useTranslation()
    const navigate = useNavigate();

    return (
        <div>
            <Row align={"middle"} gutter={[12, 12]}>
                <Col style={{cursor: "pointer"}} onClick={() => {
                    navigate(-1)
                }}>
                    <Image width={30}
                           preview={false}
                           src={leftArrowIcon}/>
                </Col>
                <Col>
                    <StyledSpan color={'Black'}
                                font={'RoboMedium24'}>
                        {(props.title?.length ?? 0) > 0 ? props.title : t('Back')}
                    </StyledSpan>
                </Col>
            </Row>
        </div>
    );
}

export default BackButtonView;
