import {ParsedErrorAction} from "./ErrorAction";

export const PRIVILEGES_ERROR_CODE: number = -901
export const BLOCK_USER_ERROR_CODE: number = -1009
export const NOT_EXIST_RESOURCE_ERROR_CODE: number = -1000

export default class ApiError {
    constructor(
        public readonly code: number,
        public readonly action: ParsedErrorAction,
        public readonly msg?: string,
        public readonly error?: Error
    ) {
    }
}
